import React, { useState } from 'react';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import { useLocation } from 'react-router-dom';

function ApiTestComponent() {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
 
  const location = useLocation();


  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const apiUrl = params.get('apiUrl');

  const [apiUrlState, setApiUrlState] = useState(apiUrl);
  const [tokenState, setTokenState] = useState(token);


  // Function to test the API
  const testApi = async () => {
    try {
      const response = await fetch(apiUrlState, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${tokenState}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        setResponse(data);
        setError(null);
      } else {
        setError('Failed to fetch data');
        setResponse(null);
      }
    } catch (error) {
      setError('Error: ' + error.message);
      setResponse(null);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', direction:'ltr', alignItems: 'center', height: '100vh' }}> {/* Center the content vertically and horizontally */}
      <div style={{ width: '80%', maxWidth: '600px' }}> {/* Limit the width to 80% or 600px (whichever is smaller) */}
        <label htmlFor="apiUrl">
          API URL:
          <input
            type="text"
            id="apiUrl"
            value={apiUrl}
            onChange={(e) => setApiUrlState(e.target.value)}
            style={{ width: '100%', fontSize: '14px', padding: '5px' }} // Set width, font size, and padding
          />
        </label>
        <br />
        <label htmlFor="token">
          Token:
          <textarea
            id="token"
            value={token}
            onChange={(e) => setTokenState(e.target.value)}
            style={{ width: '100%', fontSize: '14px', padding: '5px', minHeight: '100px' }} // Set full width, font size, padding, and minimum height
          />
        </label>
        <br />
        <button onClick={testApi}>Test API</button>
        <br />
        {response && <p style={{ fontSize: '14px' }}>Response: {JSON.stringify(response)}</p>} {/* Set font size for response */}
        {error && <p style={{ fontSize: '14px', color: 'red' }}>Error: {error}</p>} {/* Set font size and color for error */}
      </div>
    </div>
  );
}

export default ApiTestComponent;
