// config.js
////http://fortest_motakawin_mfep.gov.dz:3000/
///const API_BASE_URL = 'http://fortest_motakawin_mfep.gov.dz:8000';
///const API_BASE_URL = '192.168.1.7:8000';

////const API_BASE_URL = 'http://localhost:8000';
//const API_BASE_URL = 'http://localhost:8000';
const API_BASE_URL = 'https://tachbik.machrou3.com';
//const API_BASE_URL = 'https://sasi.machrou3.com';
///const API_BASE_URL = 'http://khidma.mfp.gov.dz:8080/MoutakawinLaravel10/public';


///const API_BASE_URL = 'http://api.mfep.gov.dz';
///const APP_PREFIX = 'moutakawinMFEP_';
///const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const APP_PREFIX = 'moutakawinMFEP_';

// Export both variables
export { API_BASE_URL, APP_PREFIX };
 

