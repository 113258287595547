import React, { useState, useEffect } from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';

import FacebookIcon from '@mui/icons-material/Facebook';
import Avatar from '@mui/material/Avatar';


import appico from '../src/img/logo.png';
import { Box, Typography, Link, Grid, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';


export default function Footer() {


  const theme = useTheme();


  return (

    <>



      <Container
      sx={{
        
        mt:'50px',}}

      maxWidth="false" disableGutters> {/* Disable default margin */}
        <Box
          component="footer"
          sx={{
            mt:4,
            position: 'fixed', // Fixed position to keep it at the bottom
            bottom: 0, // Aligning it to the bottom
            width: '100%', // Full width
            py: 1,
            borderTop: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
             backgroundColor: theme.palette.background.paper,
            backgroundColor:"#d6e6f6",
            fontFamily: theme.typography.fontFamily,
            textAlign: 'center',
          }}
        >
          <Grid container spacing={0} justifyContent="center">
            <Grid item sx={{ mt: 1 }}>
              <Typography variant="body2" color="text.secondary">
                <Link
                  href="https://www.mfep.gov.dz/"
                  underline="none" // Remove underline
                  sx={{
                    fontFamily: '"Tajawal", sans-serif',
                    color: "#191970",
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: theme.palette.text.primary, // Use theme colors
                  }}
                >
                  وزارة التكوين والتعليم المهنيين
                {' '}
                {'© '}
                {new Date().getFullYear()}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>

    </>
  )















}


