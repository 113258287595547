import React, { useState, useEffect, useRef } from "react";

import { Typography, Box } from "@mui/material";
import axios from "axios";

import { InputLabel, Select, MenuItem } from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ApiIcon from "@mui/icons-material/Api";
import FunctionsIcon from "@mui/icons-material/Functions";
import useAuthentication_etab from "./useAuthentication_etab";
import LinearProgress from "@mui/material/LinearProgress";
import StorageIcon from "@mui/icons-material/Storage";
import Autocomplete from "@mui/material/Autocomplete";
import AlertTitle from "@mui/material/AlertTitle";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { API_BASE_URL } from './config'; // Adjust the path if necessary

import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  arSD,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import ReplayIcon from "@mui/icons-material/Replay";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { CacheProvider } from "@emotion/react";

import Checkbox from "@mui/material/Checkbox";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import XLSX from "sheetjs-style";
import Footer from "./Footer";
import Header from "./Header";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { createTheme, ThemeProvider, Theme } from "@mui/material/styles";
import createCache from "@emotion/cache";

import "../containers/amirifont.css"; // Import the font CSS file
import Divider from "@mui/material/Divider";
import LockIcon from "@mui/icons-material/Lock";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";

const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme(
  {
    direction: "rtl",
    overrides: {
      MuiOutlinedInput: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: hoverColor,
          },
          "&$focused $notchedOutline": {
            borderColor: focusColor,
          },
        },
        notchedOutline: {
          borderColor: defaultColor,
        },
      },
    },
    fieldset: {
      fontFamily: '"Changa", sans-serif',
      fontSize: 45,
    },
  },
  arSD
);

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

async function loginUser(email, MotDePass) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email, MotDePass: MotDePass }),
    });

    if (!response.ok) {
      throw new Error("Invalid credentials");
    }

    const data = await response.json();
    const token = data.token;
    return token;
  } catch (error) {
    console.error("Error logging in:", error.message);
    return null;
  }
}

const StyledBox = styled("div")(({ theme }) => ({
  height: 400,
  width: "100%",
  "& .MuiDataGrid-cell--editable": {
    backgroundColor:
      theme.palette.mode === "dark" ? "#376331" : "rgb(217 243 190)",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1
      })`,
    color: theme.palette.mode === "dark" ? "#ff4343" : "#750f0f",
  },
}));

let rowsdata = [];
let promiseTimeout;
function validateName(username) {
  const alphabetRegex = /^[a-zA-Z\s]+$/;
  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      const isValid = alphabetRegex.test(username);
      resolve(
        isValid ? null : "Username must contain only alphabets (a to z)."
      );
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

let promiseTimeoutar;
function validateNamear(username) {
  const arabicAlphabetRegex = /^[\u0600-\u06FF\s]+$/;

  return new Promise((resolve) => {
    promiseTimeoutar = setTimeout(() => {
      const isValid = arabicAlphabetRegex.test(username);
      resolve(isValid ? null : "Username must contain only Arabic alphabets.");
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

const apiUrl = `${API_BASE_URL}/api/getapis_functions`;

function Migrate_hfsql() {
  const {
    IDetablissement,
    IDDFEP,
    IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep,
  } = useAuthentication_etab();

  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [isAdding, setIsAdding] = React.useState(false);
  const [deletedRowId, setDeletedRowId] = useState(null);
  const [idforsave, setidforsave] = useState(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isConfirmationOpen_token, setConfirmationOpen_token] = useState(false);
  const [confirmed_token, setconfirmed_token] = useState(false);

  const [responsevalue, setresponsevalue] = useState(null);
  const [responsevalue_gettable, setresponsevalue_gettable] = useState(null);

  const [selectedtable, setselectedtable] = useState(false);

  const [isSaveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
  const [editedValue, setEditedValue] = useState("");

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const [valuegenpw, setvaluegenpw] = useState("");
  const [valuegenpw_nomUser, setvaluegenpw_nomUser] = useState("");

  const [deleted_NomAr, setDeleted_NomAr] = useState(null);
  const [deleted_PrenomAr, setDeleted_PrenomAr] = useState(null);

  const [privilege_users_data, setprivilege_users_data] = useState([]);
  const [selectedID_privilege_users, setselectedID_privilege_users] =
    useState("");

  const useStyles_listdfep = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
        fontFamily: '"Tajawal", sans-serif',
        fontSize: 18,
        direction: "rtl",
      },
    },
    inputRoot: {
      color: "#191970",

      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        fontFamily: '"Tajawal", sans-serif',
        fontWeight: 700,
        color: "#191970",
        // Default left padding is 6px
        paddingLeft: 26,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#add8e6",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "red",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "purple",
      },
    },
  }));

  const classes_listdfep = useStyles_listdfep();

  const editedValueRef = useRef(editedValue);

  // Update the ref whenever editedValue changes
  useEffect(() => {
    editedValueRef.current = editedValue;
  }, [editedValue]);

  const navigate = useNavigate();

  const [selectidtable, setselectidtable] = useState([]);

  const [selecttable, setselecttable] = useState(null);



  const btnclicked = (event, values) => {
    if (selectidtable) {


      let endpoint = "";

      if (Array.isArray(selectidtable) && selectidtable.length === 1) {
        // Single selection
        endpoint = `connectToHfSql_anytable_multiple/${selectidtable[0].label}`;
      } else if (Array.isArray(selectidtable) && selectidtable.length > 1) {
        // Multiple selections
        const labels = selectidtable.map(option => option.label);
        endpoint = `connectToHfSql_anytable_multiple/${JSON.stringify(labels)}`;
      } else {
        // Handle case when selectidtable is null or undefined
        console.error("No selected tables");
        return;
      }


      setselectedtable(true);
      setresponsevalue_gettable(null);
      let nbselected = 0;

      axios
        .get(`${API_BASE_URL}/api/${endpoint}`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        })
        .then((response) => {
          // Handle the response here if needed
          console.log(response.data); // Example: Log the response data
          ///setresponsevalue(response.data);
          setresponsevalue(prevValue => prevValue ? prevValue + response.data : response.data);
          nbselected = nbselected + 1;


        })
        .catch((error) => {
          // Handle errors here
          console.error("Error calling Laravel route:", error);
          setresponsevalue(error);
          nbselected = nbselected + 1;

        })
        .finally(() => {

          setselectedtable(false);


        });
    }
  };



  const onchange_table = (event, values) => {


    if (values) {

      ////alert(values[0].value);

      if (Array.isArray(values)) {
        // Multiple selections
        setselectidtable(values);
        // Handle multiple selections as needed
      } else {
        // Single selection
        if (values) {
          setselectidtable([values]);
          setselecttable(values.value);
          // Handle single selection as needed
        } else {
          // Handle case when values is null (e.g., when clearing the selection)
          setselectidtable([]);
          setselecttable('');
        }
      }


      setselectedtable(true);
      setresponsevalue_gettable(null);
      setresponsevalue(null);
      axios
        .get(`${API_BASE_URL}/api/getMigrate_hfsql/` + values[0].value, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        })
        .then((response) => {
          // Handle the response here if needed
          console.log(response.data); // Example: Log the response data
          setresponsevalue_gettable(response.data);
          setselectedtable(false);
        })
        .catch((error) => {
          setselectedtable(false);
          setresponsevalue_gettable(error);
          // Handle errors here
          console.error("Error calling Laravel route:", error);
        });



    } else {
      return;
    }
  };

  const options_tables = [
    { label: "Specialites", indexvalue: 0, value: "Specialites" },
    { label: "Offre", indexvalue: 1, value: "Offre" },
    { label: "Dfep", indexvalue: 2, value: "Dfep" },
    { label: "Apprenant", indexvalue: 3, value: "Apprenant" },
    { label: "Candidat", indexvalue: 4, value: "Candidat" },
    { label: "apprenant_section_semstre", indexvalue: 5, value: "apprenant_section_semstre" },
    { label: "apprenant_sig", indexvalue: 6, value: "apprenant_sig" },
    { label: "section_semestre", indexvalue: 7, value: "section_semestre" },
    { label: "section", indexvalue: 8, value: "section" },
    { label: "encadrement", indexvalue: 9, value: "encadrement" },
    { label: "section_semestre_module", indexvalue: 10, value: "section_semestre_module" },
    { label: "apprenant_fin", indexvalue: 11, value: "apprenant_fin" },
    { label: "encadrement_diplome", indexvalue: 12, value: "encadrement_diplome" },
    { label: "competance", indexvalue: 13, value: "competance" },
    { label: "employeur", indexvalue: 14, value: "employeur" },
    { label: "stageperfectionnemnt", indexvalue: 16, value: "stageperfectionnemnt" },
    { label: "locaux", indexvalue: 17, value: "locaux" },
    { label: "logement", indexvalue: 18, value: "logement" },
    { label: "cal_tachedetail", indexvalue: 21, value: "cal_tachedetail" },
    { label: "decretmfep", indexvalue: 23, value: "decretmfep" },
    { label: "ets_form", indexvalue: 24, value: "ets_form" },
    { label: "encadrement_compinge", indexvalue: 25, value: "encadrement_compinge" },
    { label: "equipement_interv", indexvalue: 26, value: "equipement_interv" },
    { label: "vehicule", indexvalue: 27, value: "vehicule" },
    { label: "specialite", indexvalue: 28, value: "specialite" },
    { label: "operations", indexvalue: 29, value: "operations" },
    { label: "email", indexvalue: 30, value: "email" },
    { label: "encadrement_fautes_professionnelles", indexvalue: 31, value: "encadrement_fautes_professionnelles" },
    { label: "communn", indexvalue: 32, value: "communn" },
    { label: "accesuser", indexvalue: 35, value: "accesuser" },
    { label: "apc", indexvalue: 36, value: "apc" },
    { label: "etsform_branche", indexvalue: 37, value: "etsform_branche" },
    { label: "fonctionnaire", indexvalue: 38, value: "fonctionnaire" },
    { label: "citoyen", indexvalue: 40, value: "citoyen" },
    { label: "citoyenec", indexvalue: 41, value: "citoyenec" },
    { label: "utilisateur", indexvalue: 42, value: "utilisateur" },
    { label: "apc1", indexvalue: 43, value: "apc1" },
    { label: "emploitemp", indexvalue: 44, value: "emploitemp" },
    { label: "grade", indexvalue: 45, value: "grade" },
    { label: "cal_tache", indexvalue: 46, value: "cal_tache" },
    { label: "privelege_utilisateur", indexvalue: 47, value: "privelege_utilisateur" },
    { label: "semestre_formation", indexvalue: 48, value: "semestre_formation" },
    { label: "budget", indexvalue: 49, value: "budget" },
    { label: "miseajour", indexvalue: 50, value: "miseajour" },
    { label: "participant", indexvalue: 51, value: "participant" },
    { label: "privelege", indexvalue: 52, value: "privelege" },
    { label: "compinge", indexvalue: 53, value: "compinge" },
    { label: "deroulemnt_cntr", indexvalue: 54, value: "deroulemnt_cntr" },
    { label: "operation", indexvalue: 56, value: "operation" },
    { label: "session", indexvalue: 57, value: "session" },
    { label: "emailreponce", indexvalue: 58, value: "emailreponce" },
    { label: "encadrement_fonctions", indexvalue: 59, value: "encadrement_fonctions" },
    { label: "mode_formation", indexvalue: 61, value: "mode_formation" },
    { label: "specialite_module", indexvalue: 62, value: "specialite_module" },
    { label: "activitéprogramme", indexvalue: 63, value: "activitéprogramme" },
    { label: "apprenant_section_semstre_module", indexvalue: 64, value: "apprenant_section_semstre_module" },
    { label: "membrepv", indexvalue: 65, value: "membrepv" },
    { label: "semestre", indexvalue: 66, value: "semestre" },
    { label: "session_dfep", indexvalue: 67, value: "session_dfep" },
    { label: "session_mode_formation", indexvalue: 68, value: "session_mode_formation" },
    { label: "action_annee", indexvalue: 69, value: "action_annee" },
    { label: "annee_formation", indexvalue: 70, value: "annee_formation" },
    { label: "compingedfep", indexvalue: 71, value: "compingedfep" },
    { label: "direction", indexvalue: 74, value: "direction" },
    { label: "direction_cal_tache", indexvalue: 75, value: "direction_cal_tache" },
    { label: "direction_cal_tachedetail", indexvalue: 76, value: "direction_cal_tachedetail" },
    { label: "fonctions", indexvalue: 77, value: "fonctions" },
    { label: "mode", indexvalue: 78, value: "mode" },
    { label: "nationalites", indexvalue: 79, value: "nationalites" },
    { label: "nature_etsf", indexvalue: 80, value: "nature_etsf" },
    { label: "niveau_scol", indexvalue: 81, value: "niveau_scol" },
    { label: "niveau_scolaires", indexvalue: 82, value: "niveau_scolaires" },
    { label: "niveauqualifications", indexvalue: 83, value: "niveauqualifications" },
    { label: "order", indexvalue: 84, value: "order" },
    { label: "pays", indexvalue: 85, value: "pays" },
    { label: "profil", indexvalue: 86, value: "profil" },
    { label: "sessions", indexvalue: 87, value: "sessions" },
    { label: "souscategorie", indexvalue: 88, value: "souscategorie" },
    { label: "validationdossier", indexvalue: 89, value: "validationdossier" },
    { label: "action", indexvalue: 90, value: "action" },
    { label: "apprenant_absence", indexvalue: 91, value: "apprenant_absence" },
    { label: "axe_tache", indexvalue: 92, value: "axe_tache" },
    { label: "candidat_update", indexvalue: 93, value: "candidat_update" },
    { label: "categorie", indexvalue: 94, value: "categorie" },
    { label: "corps", indexvalue: 95, value: "corps" },
    { label: "logement_causeoccup_logement_nature", indexvalue: 96, value: "logement_causeoccup_logement_nature" },
    { label: "niveau_scol_enca", indexvalue: 97, value: "niveau_scol_enca" },
    { label: "nomenclature_ver", indexvalue: 98, value: "nomenclature_ver" },
    { label: "operationdecision_operations", indexvalue: 99, value: "operationdecision_operations" },
    { label: "operationnature_operations", indexvalue: 100, value: "operationnature_operations" },
    { label: "poste_budgetaire", indexvalue: 102, value: "poste_budgetaire" },
    { label: "programme", indexvalue: 103, value: "programme" },
    { label: "section_pv", indexvalue: 104, value: "section_pv" },
    { label: "service", indexvalue: 105, value: "service" },
    { label: "sousaction", indexvalue: 106, value: "sousaction" },
    { label: "trimestre", indexvalue: 107, value: "trimestre" },
    { label: "utilisateur_mode_formation", indexvalue: 108, value: "utilisateur_mode_formation" },
    { label: "wilayas", indexvalue: 109, value: "wilayas" },
    { label: "activité", indexvalue: 110, value: "activité" },
    { label: "activitéencadreur", indexvalue: 111, value: "activitéencadreur" },
    { label: "activitépresse", indexvalue: 112, value: "activitépresse" },
    { label: "annee", indexvalue: 113, value: "annee" },
    { label: "atelier", indexvalue: 114, value: "atelier" },
    { label: "branche", indexvalue: 115, value: "branche" },
    { label: "certificat_insc", indexvalue: 116, value: "certificat_insc" },
    { label: "compingeperiode", indexvalue: 117, value: "compingeperiode" },
    { label: "concours_examenprofessionnel", indexvalue: 118, value: "concours_examenprofessionnel" },
    { label: "corp", indexvalue: 119, value: "corp" },
    { label: "employeur_branche", indexvalue: 120, value: "employeur_branche" },
    { label: "employeur_nature", indexvalue: 121, value: "employeur_nature" },
    { label: "equipement_mouv", indexvalue: 122, value: "equipement_mouv" },
    { label: "equipement_transfert", indexvalue: 123, value: "equipement_transfert" },
    { label: "ets_scolaire", indexvalue: 126, value: "ets_scolaire" },
    { label: "fautes_professionnelles", indexvalue: 127, value: "fautes_professionnelles" },
    { label: "maitre_apprenti", indexvalue: 128, value: "maitre_apprenti" },
    { label: "ministere", indexvalue: 129, value: "ministere" },
    { label: "mode_gestion", indexvalue: 130, value: "mode_gestion" },
    { label: "module", indexvalue: 131, value: "module" },
    { label: "observationaprenants", indexvalue: 132, value: "observationaprenants" },
    { label: "operationoffre", indexvalue: 133, value: "operationoffre" },
    { label: "portefeuille", indexvalue: 135, value: "portefeuille" },
    { label: "pvsemstriel", indexvalue: 136, value: "pvsemstriel" },
    { label: "qualification_dplm", indexvalue: 137, value: "qualification_dplm" },
    { label: "situation", indexvalue: 138, value: "situation" },
    { label: "situationadministrat", indexvalue: 139, value: "situationadministrat" },
    { label: "sous_programme", indexvalue: 140, value: "sous_programme" },
    { label: "specialite_programme", indexvalue: 141, value: "specialite_programme" },
    { label: "titre", indexvalue: 142, value: "titre" },
    { label: "wilaya", indexvalue: 143, value: "wilaya" },
    { label: "activitéinvite", indexvalue: 145, value: "activitéinvite" },
    { label: "avenat", indexvalue: 146, value: "avenat" },
    { label: "axe_principale", indexvalue: 147, value: "axe_principale" },
    { label: "bureau", indexvalue: 148, value: "bureau" },
    { label: "bureauetude", indexvalue: 149, value: "bureauetude" },
    { label: "candidat_memo", indexvalue: 150, value: "candidat_memo" },
    { label: "certificat_scol", indexvalue: 151, value: "certificat_scol" },
    { label: "competences_activites_professionnelles", indexvalue: 152, value: "competences_activites_professionnelles" },
    { label: "competences_savoirstheoriques", indexvalue: 153, value: "competences_savoirstheoriques" },
    { label: "compte", indexvalue: 154, value: "compte" },
    { label: "convention", indexvalue: 155, value: "convention" },
    { label: "convocation", indexvalue: 156, value: "convocation" },
    { label: "decision_eval_mdl", indexvalue: 157, value: "decision_eval_mdl" },
    { label: "decision_eval_mdlavr", indexvalue: 158, value: "decision_eval_mdlavr" },
    { label: "decision_eval_semestravr", indexvalue: 159, value: "decision_eval_semestravr" },
    { label: "decision_evalf", indexvalue: 160, value: "decision_evalf" },
    { label: "decision_evals", indexvalue: 161, value: "decision_evals" },
    { label: "decision_insc", indexvalue: 162, value: "decision_insc" },
    { label: "decision_orie", indexvalue: 163, value: "decision_orie" },
    { label: "decrettype", indexvalue: 164, value: "decrettype" },
    { label: "dercrte_memo", indexvalue: 165, value: "dercrte_memo" },
    { label: "encadrement_absence", indexvalue: 166, value: "encadrement_absence" },
    { label: "equipement_etat", indexvalue: 167, value: "equipement_etat" },
    { label: "etatmatrice", indexvalue: 168, value: "etatmatrice" },
    { label: "failed_jobs", indexvalue: 169, value: "failed_jobs" },
    { label: "fautes_professionnelles_degre", indexvalue: 170, value: "fautes_professionnelles_degre" },
    { label: "filiere", indexvalue: 171, value: "filiere" },
    { label: "idactivitéobs", indexvalue: 172, value: "idactivitéobs" },
    { label: "locaux_etatactual", indexvalue: 173, value: "locaux_etatactual" },
    { label: "migrate_hfsql", indexvalue: 174, value: "migrate_hfsql" },
    { label: "module_compétences", indexvalue: 175, value: "module_compétences" },
    { label: "niveau_fp", indexvalue: 178, value: "niveau_fp" },
    { label: "nom_equipement", indexvalue: 179, value: "nom_equipement" },
    { label: "nomenclature_mode", indexvalue: 180, value: "nomenclature_mode" },
    { label: "nomenclaturecorp", indexvalue: 181, value: "nomenclaturecorp" },
    { label: "procedure_disciplinaire", indexvalue: 187, value: "procedure_disciplinaire" },
    { label: "recommandations", indexvalue: 188, value: "recommandations" },
    { label: "recommandationsgenerale", indexvalue: 189, value: "recommandationsgenerale" },
    { label: "suivi", indexvalue: 190, value: "suivi" },
    { label: "tache", indexvalue: 191, value: "tache" },
    { label: "unitemodulaire", indexvalue: 192, value: "unitemodulaire" },
    { label: "users", indexvalue: 193, value: "users" },
    { label: "zone", indexvalue: 194, value: "zone" },
    { label: "actiontype", indexvalue: 195, value: "actiontype" },
    { label: "activitéencadreurtype", indexvalue: 196, value: "activitéencadreurtype" },
    { label: "activitéobs", indexvalue: 197, value: "activitéobs" },
    { label: "activitépressetype", indexvalue: 198, value: "activitépressetype" },
    { label: "activitétype", indexvalue: 199, value: "activitétype" },
    { label: "apis_functions", indexvalue: 200, value: "apis_functions" },
    { label: "apis_userlist_functions", indexvalue: 201, value: "apis_userlist_functions" },
    { label: "apis_userslist", indexvalue: 202, value: "apis_userslist" },
    { label: "apprenant_regime", indexvalue: 203, value: "apprenant_regime" },
    { label: "association_clube", indexvalue: 204, value: "association_clube" },
    { label: "beneficiaire", indexvalue: 205, value: "beneficiaire" },
    { label: "branches", indexvalue: 206, value: "branches" },
    { label: "bureauetudenature", indexvalue: 207, value: "bureauetudenature" },
    { label: "citoyenetr", indexvalue: 208, value: "citoyenetr" },
    { label: "communes", indexvalue: 209, value: "communes" },
    { label: "cycle_scol", indexvalue: 210, value: "cycle_scol" },
    { label: "decision", indexvalue: 211, value: "decision" },
    { label: "decision_eval_final", indexvalue: 212, value: "decision_eval_final" },
    { label: "decision_eval_semestr", indexvalue: 213, value: "decision_eval_semestr" },
    { label: "decret", indexvalue: 214, value: "decret" },
    { label: "decret_etat", indexvalue: 215, value: "decret_etat" },
    { label: "diplome", indexvalue: 216, value: "diplome" },
    { label: "dossier", indexvalue: 217, value: "dossier" },
    { label: "employeurtype", indexvalue: 218, value: "employeurtype" },
    { label: "equipement_obs", indexvalue: 219, value: "equipement_obs" },
    { label: "equipementexploi", indexvalue: 220, value: "equipementexploi" },
    { label: "felicitations_sanctiontype", indexvalue: 223, value: "felicitations_sanctiontype" },
    { label: "fonctionsnature", indexvalue: 224, value: "fonctionsnature" },
    { label: "fournisseur", indexvalue: 225, value: "fournisseur" },
    { label: "idequipement_etattype", indexvalue: 226, value: "idequipement_etattype" },
    { label: "idsituationadministrat_type", indexvalue: 227, value: "idsituationadministrat_type" },
    { label: "jour", indexvalue: 228, value: "jour" },
    { label: "listinscription", indexvalue: 229, value: "listinscription" },
    { label: "listinscription_update", indexvalue: 230, value: "listinscription_update" },
    { label: "localtype", indexvalue: 231, value: "localtype" },
    { label: "logement_causeoccup", indexvalue: 232, value: "logement_causeoccup" },
    { label: "logement_nature", indexvalue: 233, value: "logement_nature" },
    { label: "logementnaturejur", indexvalue: 234, value: "logementnaturejur" },
    { label: "logementoccupe", indexvalue: 235, value: "logementoccupe" },
    { label: "logementtype", indexvalue: 236, value: "logementtype" },
    { label: "mention", indexvalue: 237, value: "mention" },
    { label: "migrations", indexvalue: 238, value: "migrations" },
    { label: "mode_promotion", indexvalue: 239, value: "mode_promotion" },
    { label: "mode_recrutement", indexvalue: 240, value: "mode_recrutement" },
    { label: "module_modeenseigne", indexvalue: 241, value: "module_modeenseigne" },
    { label: "mois", indexvalue: 242, value: "mois" },
    { label: "nationalitee", indexvalue: 244, value: "nationalitee" },
    { label: "nature", indexvalue: 245, value: "nature" },
    { label: "nature_etss", indexvalue: 246, value: "nature_etss" },
    { label: "naturedirection", indexvalue: 247, value: "naturedirection" },
    { label: "oauth_personal_access_clients", indexvalue: 248, value: "oauth_personal_access_clients" },
    { label: "offert", indexvalue: 249, value: "offert" },
    { label: "offres", indexvalue: 250, value: "offres" },
    { label: "operationdecision", indexvalue: 251, value: "operationdecision" },
    { label: "operationetat", indexvalue: 252, value: "operationetat" },
    { label: "operationnature", indexvalue: 253, value: "operationnature" },
    { label: "operationtype", indexvalue: 254, value: "operationtype" },
    { label: "ordernature", indexvalue: 255, value: "ordernature" },
    { label: "participantnature", indexvalue: 256, value: "participantnature" },
    { label: "privilege_users", indexvalue: 258, value: "privilege_users" },
    { label: "propriete_location", indexvalue: 259, value: "propriete_location" },
    { label: "region", indexvalue: 260, value: "region" },
    { label: "secteur", indexvalue: 261, value: "secteur" },
    { label: "secteurs", indexvalue: 262, value: "secteurs" },
    { label: "siege_annex", indexvalue: 263, value: "siege_annex" },
    { label: "sitfamille", indexvalue: 264, value: "sitfamille" },
    { label: "statusets", indexvalue: 265, value: "statusets" },
    { label: "syndicat", indexvalue: 266, value: "syndicat" },
    { label: "tbudget", indexvalue: 267, value: "tbudget" },
    { label: "type_interv", indexvalue: 268, value: "type_interv" },
    { label: "typemodule", indexvalue: 269, value: "typemodule" },
    { label: "vehiculeenergie", indexvalue: 270, value: "vehiculeenergie" },
    { label: "vehiculeetat", indexvalue: 271, value: "vehiculeetat" },
    { label: "vehiculegenre", indexvalue: 272, value: "vehiculegenre" },
    { label: "vehiculesmarque", indexvalue: 273, value: "vehiculesmarque" },
    { label: "vehiculestype", indexvalue: 274, value: "vehiculestype" },
  ];
  // Add more options as needed



  // Sort the options_tables array alphabetically by label
  options_tables.sort((a, b) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    if (!loadingauth) {
      if (
        IDetablissement === null ||
        IDDFEP === null ||
        IDNature_etsF === null ||
        Number(IDNature_etsF) !== 1
      ) {
        // Navigate to the 404 page if any value is missing
        navigate("/404");
        return;
      }
    }
  }, [
    IDetablissement,
    IDDFEP,
    IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    navigate,

    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep,
  ]); // Add navigate to dependencies

  if (loadingauth) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress color="success" />
      </Box>
    );
  }



  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
  const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
    {
      title: 'The Lord of the Rings: The Return of the King',
      year: 2003,
    },
    { title: 'The Good, the Bad and the Ugly', year: 1966 },
    { title: 'Fight Club', year: 1999 },
    {
      title: 'The Lord of the Rings: The Fellowship of the Ring',
      year: 2001,
    },
    {
      title: 'Star Wars: Episode V - The Empire Strikes Back',
      year: 1980,
    },
    { title: 'Forrest Gump', year: 1994 },
    { title: 'Inception', year: 2010 },
    {
      title: 'The Lord of the Rings: The Two Towers',
      year: 2002,
    },
    { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { title: 'Goodfellas', year: 1990 },
    { title: 'The Matrix', year: 1999 },
    { title: 'Seven Samurai', year: 1954 },
    {
      title: 'Star Wars: Episode IV - A New Hope',
      year: 1977,
    },
    { title: 'City of God', year: 2002 },
    { title: 'Se7en', year: 1995 },
    { title: 'The Silence of the Lambs', year: 1991 },
    { title: "It's a Wonderful Life", year: 1946 },
    { title: 'Life Is Beautiful', year: 1997 },
    { title: 'The Usual Suspects', year: 1995 },
    { title: 'Léon: The Professional', year: 1994 },
    { title: 'Spirited Away', year: 2001 },
    { title: 'Saving Private Ryan', year: 1998 },
    { title: 'Once Upon a Time in the West', year: 1968 },
    { title: 'American History X', year: 1998 },
    { title: 'Interstellar', year: 2014 },
    { title: 'Casablanca', year: 1942 },
    { title: 'City Lights', year: 1931 },
    { title: 'Psycho', year: 1960 },
    { title: 'The Green Mile', year: 1999 },
    { title: 'The Intouchables', year: 2011 },
    { title: 'Modern Times', year: 1936 },
    { title: 'Raiders of the Lost Ark', year: 1981 },
    { title: 'Rear Window', year: 1954 },
    { title: 'The Pianist', year: 2002 },
    { title: 'The Departed', year: 2006 },
    { title: 'Terminator 2: Judgment Day', year: 1991 },
    { title: 'Back to the Future', year: 1985 },
    { title: 'Whiplash', year: 2014 },
    { title: 'Gladiator', year: 2000 },
    { title: 'Memento', year: 2000 },
    { title: 'The Prestige', year: 2006 },
    { title: 'The Lion King', year: 1994 },
    { title: 'Apocalypse Now', year: 1979 },
    { title: 'Alien', year: 1979 },
    { title: 'Sunset Boulevard', year: 1950 },
    {
      title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
      year: 1964,
    },
    { title: 'The Great Dictator', year: 1940 },
    { title: 'Cinema Paradiso', year: 1988 },
    { title: 'The Lives of Others', year: 2006 },
    { title: 'Grave of the Fireflies', year: 1988 },
    { title: 'Paths of Glory', year: 1957 },
    { title: 'Django Unchained', year: 2012 },
    { title: 'The Shining', year: 1980 },
    { title: 'WALL·E', year: 2008 },
    { title: 'American Beauty', year: 1999 },
    { title: 'The Dark Knight Rises', year: 2012 },
    { title: 'Princess Mononoke', year: 1997 },
    { title: 'Aliens', year: 1986 },
    { title: 'Oldboy', year: 2003 },
    { title: 'Once Upon a Time in America', year: 1984 },
    { title: 'Witness for the Prosecution', year: 1957 },
    { title: 'Das Boot', year: 1981 },
    { title: 'Citizen Kane', year: 1941 },
    { title: 'North by Northwest', year: 1959 },
    { title: 'Vertigo', year: 1958 },
    {
      title: 'Star Wars: Episode VI - Return of the Jedi',
      year: 1983,
    },
    { title: 'Reservoir Dogs', year: 1992 },
    { title: 'Braveheart', year: 1995 },
    { title: 'M', year: 1931 },
    { title: 'Requiem for a Dream', year: 2000 },
    { title: 'Amélie', year: 2001 },
    { title: 'A Clockwork Orange', year: 1971 },
    { title: 'Like Stars on Earth', year: 2007 },
    { title: 'Taxi Driver', year: 1976 },
    { title: 'Lawrence of Arabia', year: 1962 },
    { title: 'Double Indemnity', year: 1944 },
    {
      title: 'Eternal Sunshine of the Spotless Mind',
      year: 2004,
    },
    { title: 'Amadeus', year: 1984 },
    { title: 'To Kill a Mockingbird', year: 1962 },
    { title: 'Toy Story 3', year: 2010 },
    { title: 'Logan', year: 2017 },
    { title: 'Full Metal Jacket', year: 1987 },
    { title: 'Dangal', year: 2016 },
    { title: 'The Sting', year: 1973 },
    { title: '2001: A Space Odyssey', year: 1968 },
    { title: "Singin' in the Rain", year: 1952 },
    { title: 'Toy Story', year: 1995 },
    { title: 'Bicycle Thieves', year: 1948 },
    { title: 'The Kid', year: 1921 },
    { title: 'Inglourious Basterds', year: 2009 },
    { title: 'Snatch', year: 2000 },
    { title: '3 Idiots', year: 2009 },
    { title: 'Monty Python and the Holy Grail', year: 1975 },
  ];

  // Define a counter variable outside of the component
  let count = 0;

  return (
    <>
      <Header
        IDetablissement={IDetablissement}
        IDDFEP={IDDFEP}
        IDNature_etsF={IDNature_etsF}
        IDCandidat={IDCandidat}
        NumSem={NumSem}
        IDapis_userslist={IDapis_userslist}
        loadingauth={loadingauth}
        Singup_active={Singup_active}
        download_cert_scol_active={download_cert_scol_active}
        EditingInfo_active={EditingInfo_active}
        Eval_notes_active={Eval_notes_active}
        download_cert_inscription_active={download_cert_inscription_active}
        Singup_active_Dfep={Singup_active_Dfep}
        download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
        EditingInfo_active_Dfep={EditingInfo_active_Dfep}
        Eval_notes_active_Dfep={Eval_notes_active_Dfep}
        download_cert_inscription_active_Dfep={
          download_cert_inscription_active_Dfep
        }
        Singup_active_Mfep={Singup_active_Mfep}
        download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
        EditingInfo_active_Mfep={EditingInfo_active_Mfep}
        Eval_notes_active_Mfep={Eval_notes_active_Mfep}
        download_cert_inscription_active_Mfep={
          download_cert_inscription_active_Mfep
        }
      />

      <Box
        sx={{
          "& .super-app-theme--header": {
            fontFamily: "Amiri",
            fontSize: "20px",
            fontWeight: "bold", // Make the font bold
            backgroundColor: "#B9D3EE",
            borderRight: "1px solid #ccc", // Add this line to apply right border to the cell
          },
        }}
      >
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={themetextfieldnew}>
            {!!snackbar && (
              <Snackbar
                open
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                sx={{
                  marginTop: 12,
                }}
              >
                <Alert
                  {...snackbar}
                  onClose={handleCloseSnackbar}
                  sx={{
                    display: "flex",
                    fontFamily: "Amiri",
                    fontSize: "20px",
                    "& .MuiAlert-icon": { marginLeft: "10px" },
                    "& .MuiAlert-action": { marginRight: "8px" },
                  }} // Adjust padding here
                />
              </Snackbar>
            )}

            <Grid container>
              <Grid item sx={{ width: "100%" }}>
                <Divider sx={{ mt: "20px" }}>
                  <Grid item>
                    <Typography
                      style={{
                        color: "#191970",
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 25,
                        fontWeight: 700,
                        marginBottom: "25px",
                      }}
                      variant="h3"
                      gutterBottom
                    >
                      <StorageIcon sx={{ mr: "2px", fontSize: 30 }} />
                      نقل قاعدة بيانات من (HFSQL)
                    </Typography>
                  </Grid>
                </Divider>

                <Grid container justifyContent="center"></Grid>
              </Grid>

              <Grid
                container
                alignItems="center"
                align="center"
                justifyContent="center"
                sx={{ width: "100%", marginBottom: "10px" }}
              >
                <Grid item xs={12}>
                  <Grid item xs={5}>
                    {/* Autocomplement tables  */}

                    <CacheProvider value={cacheRtl}>
                      <Autocomplete
                        multiple
                        classes={classes_listdfep}
                        limitTags={5}
                        id="multiple-limit-tags"
                        options={options_tables}
                        onChange={onchange_table}
                        getOptionLabel={(option) => option.label}
                        ///defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}

                        renderOption={(props, option) => {
                          const { label } = option;
                          count++; // Increment the count for each option
                          return (
                            <span
                              {...props}
                              style={{
                                backgroundColor: count % 2 === 0 ? "#add8e6" : "#FFFFFF",
                                color: "#191970",
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: 18,
                                fontWeight: 700,
                                textAlign: "left",
                                direction: "ltr",
                              }}
                            >
                              {count === -1 ? "- " + label : label}
                            </span>
                          );
                        }}


                        renderInput={(params) => (
                          <Box display="flex" justifyContent="center">
                            <TextField fullWidth {...params} label="الجداول" />
                          </Box>
                        )}

                        sx={{ mt: 1, mb: 1 }}
                      />
                    </CacheProvider>

                  </Grid>
                </Grid>



              </Grid>
            </Grid>
          </ThemeProvider>
        </CacheProvider>

        {!selectedtable ? (
          <Grid container justifyContent="center" alignItems="center">
            <Button
              variant="contained"
              style={{
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 20,
                fontWeight: 700,
                marginBottom: "25px",
              }}
              onClick={btnclicked}
            >
              إجلب الجدول
            </Button>
          </Grid>
        ) : null}

        {selectedtable ? (
          <>
            <Box sx={{ width: "100%" }}>
              <LinearProgress color="success" />
            </Box>
          </>
        ) : null}

        {responsevalue_gettable ? (
          <Grid item xs={12} sx={{ direction: "ltr" }}>
            <Alert severity="info" sx={{ direction: "ltr" }}>
              <AlertTitle sx={{ direction: "ltr" }}>Info Table exist</AlertTitle>
              <Box sx={{ direction: "ltr" }}>
                <div dangerouslySetInnerHTML={{ __html: responsevalue_gettable }} />
              </Box>
            </Alert>
          </Grid>
        ) : null}


        {responsevalue ? (
          <Grid item xs={12} sx={{ direction: "ltr" }}>
            <Alert severity="info" sx={{ direction: "ltr" }}>
              <AlertTitle sx={{ direction: "ltr" }}>Info</AlertTitle>
              <Box sx={{ direction: "ltr" }}>
                <div dangerouslySetInnerHTML={{ __html: responsevalue }} />
              </Box>
            </Alert>
          </Grid>
        ) : null}
      </Box>

      <Footer />
    </>
  );
}

export default Migrate_hfsql;
