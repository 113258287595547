import React, { useState, useEffect, useRef } from "react";

import { Typography, Box } from "@mui/material";

import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { APP_PREFIX } from "./config"; // Adjust the path if necessary
import ApiIcon from "@mui/icons-material/Api";
import useAuthentication_apisuser from "./useAuthentication_apisuser";
import LinearProgress from "@mui/material/LinearProgress";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  arSD,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import ReplayIcon from "@mui/icons-material/Replay";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { CacheProvider } from "@emotion/react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import XLSX from "sheetjs-style";
import Footer from "./Footer";
import Header from "./Header";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "./config"; // Adjust the path if necessary
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { createTheme, ThemeProvider, Theme } from "@mui/material/styles";
import createCache from "@emotion/cache";

import "../containers/amirifont.css"; // Import the font CSS file
import Divider from "@mui/material/Divider";
import LockIcon from "@mui/icons-material/Lock";
import { makeStyles } from "@material-ui/core/styles";

const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme(
  {
    direction: "rtl",
    overrides: {
      MuiOutlinedInput: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: hoverColor,
          },
          "&$focused $notchedOutline": {
            borderColor: focusColor,
          },
        },
        notchedOutline: {
          borderColor: defaultColor,
        },
      },
    },
    fieldset: {
      fontFamily: '"Changa", sans-serif',
      fontSize: 45,
    },
  },
  arSD
);

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

async function loginUser(email, password) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email, password: password }),
    });

    if (!response.ok) {
      throw new Error("Invalid credentials");
    }

    const data = await response.json();
    const token = data.token;
    return token;
  } catch (error) {
    console.error("Error logging in:", error.message);
    return null;
  }
}

const StyledBox = styled("div")(({ theme }) => ({
  height: 400,
  width: "100%",
  "& .MuiDataGrid-cell--editable": {
    backgroundColor:
      theme.palette.mode === "dark" ? "#376331" : "rgb(217 243 190)",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${
      theme.palette.mode === "dark" ? 0 : 0.1
    })`,
    color: theme.palette.mode === "dark" ? "#ff4343" : "#750f0f",
  },
}));

let rowsdata = [];
let promiseTimeout;
function validateName(username) {
  const alphabetRegex = /^[a-zA-Z\s]+$/;
  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      const isValid = alphabetRegex.test(username);
      resolve(
        isValid ? null : "Username must contain only alphabets (a to z)."
      );
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

let promiseTimeoutar;
function validateNamear(username) {
  const arabicAlphabetRegex = /^[\u0600-\u06FF\s]+$/;

  return new Promise((resolve) => {
    promiseTimeoutar = setTimeout(() => {
      const isValid = arabicAlphabetRegex.test(username);
      resolve(isValid ? null : "Username must contain only Arabic alphabets.");
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEditName(params) {
  return <NameEditInputCell {...params} />;
}

const apiUrl = `${API_BASE_URL}/api/getapis_userlist_functionsforusernom`;

function CustomToolbar({
  setRows,
  setRowModesModel,
  handleExportExcel,
  columns,
}) {
  return (
    <GridToolbarContainer style={{ fontFamily: "Amiri", fontSize: "20px" }}>
      <GridToolbarColumnsButton
        style={{ fontFamily: "Amiri", fontSize: "20px" }}
      />
      <GridToolbarFilterButton
        style={{ fontFamily: "Amiri", fontSize: "20px" }}
      />
      <GridToolbarDensitySelector
        style={{ fontFamily: "Amiri", fontSize: "20px" }}
      />
      <Button
        style={{ fontFamily: "Amiri", fontSize: "20px" }}
        onClick={handleExportExcel}
        color="primary"
      >
        تنزيل كملف إكسال
      </Button>
      <GridToolbarExport style={{ fontFamily: "Amiri", fontSize: "20px" }} />

      <GridToolbarQuickFilter
        style={{ fontFamily: "Amiri", fontSize: "20px", marginRight: "25px" }}
        inputProps={{ style: { fontFamily: "Amiri", fontSize: "20px" } }}
      />
    </GridToolbarContainer>
  );
}

function ShowApiList() {
  const {
    IDetablissement,
    IDDFEP,
    IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    Nomapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep,
  } = useAuthentication_apisuser();

  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [isAdding, setIsAdding] = React.useState(false);
  const [deletedRowId, setDeletedRowId] = useState(null);
  const [idforsave, setidforsave] = useState(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
  const [editedValue, setEditedValue] = useState("");

  const [snackbar, setSnackbar] = React.useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCloseSnackbar = () => setSnackbar(null);
  const [valuegenpw, setvaluegenpw] = useState("");
  const [loadingdata_mfep, setloadingdata_mfep] = useState(false);

  const [deleted_NomAr, setDeleted_NomAr] = useState(null);
  const [deleted_PrenomAr, setDeleted_PrenomAr] = useState(null);

  const handleCloseSnackbarnew = () => {
    setSnackbarOpen(false);
    navigate("/loginEtab");
  };

  const handleSnackbarnew = () => {
    setSnackbarOpen(true);
  };

  const editedValueRef = useRef(editedValue);

  // Update the ref whenever editedValue changes
  useEffect(() => {
    editedValueRef.current = editedValue;
  }, [editedValue]);

  const navigate = useNavigate();

  // Fetch data from the API when the component mounts
  React.useEffect(() => {
    
    if (
      IDapis_userslist == null ||
      IDapis_userslist == undefined ||
      !Number(IDapis_userslist) > 0
    ) {
      return;
    }

    setloadingdata_mfep(true);

    fetch(apiUrl + "/" + IDapis_userslist, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        ///  console.log(data); // Log the data object to see its structure

        const apiRows = data.data.map((item, index) => ({
          id: item.idapis_userlist_functions,
          id_rows: index + 1,
          idapis_userlist_functions: item.idapis_userlist_functions,
          Nom: item.Nom,
          description_function: item.description_function,
          url_function: item.url_function,
          tokenofapi: item.tokenofapi,
          Nomfunction: item.Nomfunction,
          IDapis_userslist: item.IDapis_userslist,
          idapis_functions: item.idapis_functions,
          nomUser: item.nomUser,
          actions: "",
        }));
        setRows(apiRows);
        rowsdata = apiRows;
        console.log(apiRows);
        setloadingdata_mfep(false);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [IDapis_userslist]);

  const handleRowEditStop = (params, event) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridRowEditStopReasons.commit
    ) {
      event.defaultMuiPrevented = true;
      const editedRow = rows.find((row) => row.id === params.id);

      if (editedRow) {
        /// alert(`Editing stopped for ID: ${editedRow.id}`);
        // Trigger the save operation for the edited row
        handleSaveOperation(editedRow.id);
      }
    }
  };

  const handlecert_inscription = (id) => () => {
    const rowToid = rows.find((row) => row.id === id);
    if (rowToid) {
      // Extract NomAr value from the row
      const { crypted_id } = rowToid;

      ////navigate(`/ETATCertificatInscriptionPDF/${crypted_id}`);
      localStorage.setItem(
        APP_PREFIX + "download_cert_inscription_active",
        "1"
      );

      ////navigate(`/ETATCertificatInscriptionPDF/${crypted_id}`);
      window.open(
        `/ETATCertificatInscriptionPDF/${crypted_id}`,
        "_blank",
        "noopener noreferrer"
      );
    }
  };

  const handlecert_certscolaire = (id) => () => {
    const rowToid = rows.find((row) => row.id === id);
    if (rowToid) {
      // Extract NomAr value from the row
      const { crypted_id } = rowToid;

      //// navigate(`/ETATCertificatScolaritePDF/${crypted_id}`);
      localStorage.setItem(APP_PREFIX + "download_cert_scol_active", "1");

      ////navigate(`/ETATCertificatScolaritePDF/${crypted_id}`);
      window.open(
        `/ETATCertificatScolaritePDF/${crypted_id}`,
        "_blank",
        "noopener noreferrer"
      );
    }
  };

  const handleEditClick = (id) => () => {
    const rowToEdit = rows.find((row) => row.id === id);
    const editedValues = {};

    // Copy all fields from the row to the editedValues object
    Object.keys(rowToEdit).forEach((field) => {
      editedValues[field] = rowToEdit[field];
    });

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setEditedValue({ ...editedValue, [id]: editedValues });
  };

  const [idsaved, setidsaved] = useState(0);

  const [confirmationTrigger, setConfirmationTrigger] = useState(false);

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    //setidforsave(id);
    ///setConfirmationTrigger(true); // Set the trigger for confirmation
  };

  useEffect(() => {
    if (confirmationTrigger) {
      setSaveConfirmationOpen(true);
      setConfirmationTrigger(false); // Reset the trigger after executing
    }
  }, [confirmationTrigger, rowModesModel]);

  const handleDeleteClick = (id) => () => {
    const rowToDelete = rows.find((row) => row.id === id);
    if (rowToDelete) {
      // Extract NomAr value from the row
      const { ID } = rowToDelete;
      const { NomAr } = rowToDelete;
      const { PrenomAr } = rowToDelete;
      // Open the confirmation dialog
      setConfirmationOpen(true);
      setDeletedRowId(ID);
      setDeleted_NomAr(NomAr);
      setDeleted_PrenomAr(PrenomAr);
    }
  };

  const handleConfirmationResponse = async (confirmed) => {
    return;
    // Close the confirmation dialog
    setConfirmationOpen(false);

    if (confirmed) {
      const idToDelete = deletedRowId;
      setDeletedRowId(null); // Reset deletedRowId to avoid multiple deletions

      try {
        // Send the API request to delete the row from the Laravel backend
        const response = await fetch(
          `${API_BASE_URL}/api/deleteRow_fromCand/${idToDelete}`,
          {
            method: "DELETE",
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
            }
          }
        );

        if (response.ok) {
          // If the API request is successful, update the local state to remove the row
          setRows((prevRows) =>
            prevRows.filter((row) => row.id !== idToDelete)
          );
          ////////console.log('Row deleted successfully from Laravel backend.');
          setSnackbar({ children: "  تم الحذف بنجاح  ", severity: "success" });
        } else {
          console.error("Failed to delete row from Laravel backend.");
          // Handle the error or display a notification to the user
        }
      } catch (error) {
        console.error("Error during the API request:", error);
        // Handle the error or display a notification to the user
      }
    } else {
      // User clicked "Cancel," do nothing
    }
  };

  const handleSaveConfirmationResponse = async (confirmed) => {
    // Close the confirmation dialog
    setSaveConfirmationOpen(false);

    if (confirmed) {
      const idforsavea = idforsave;
      handleSaveOperation(idforsavea);
      setidforsave(null);
    } else {
      // User clicked "Cancel," do nothing
    }
  };

  const processRowUpdate = async (newRow) => {
    // Check if the row is new
    const isNewRow = rows.find((row) => row.id === newRow.id)?.isNew;

    // Preserve the original isNew value if it exists
    const updatedRow = { ...newRow, isNew: isNewRow };

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    // Display an alert with the updated row data and id
    ///alert(`Row Data After Update for ID ${newRow.id}: ${JSON.stringify(updatedRow)}`);

    try {
      // Call handleSaveOperation with the id
      await handleSaveOperation(newRow.id, updatedRow);
    } catch (error) {
      console.error("Error during handleSaveOperation:", error);
    }

    return updatedRow;
  };

  const handleSaveOperation = async (id, updatedRow) => {
    const editedRow = updatedRow;
    if (!editedRow) {
      console.error("Row not found for ID:", id);
      return;
    }

    ///alert(`Row Data After Update: ${JSON.stringify(editedRow)}`);

    try {
      let response;

      // If the row is not new, it means it's already in the database, so update it
      response = await fetch(`${API_BASE_URL}/api/updateRowCand/${id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Specify the content type
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`,
        },
        body: JSON.stringify(editedRow),
      });

      
      
      if (response.ok) {
        setRows((prevRows) =>
          prevRows.map((row) => {
            if (row.id === id) {
              // If the row being updated has the same id as the edited row
              // Update all fields except for the password
              return { ...row, isNew: false, Password: "****" };
            } else {
              // For other rows, keep them unchanged
              return row;
            }
          })
        );

        ////////console.log('Record updated successfully in the database.');

        // Set the snackbar message
        setSnackbar({ children: "  تم الحفظ  ", severity: "success" });

        // Set the row mode to view after saving
        setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View },
        });
      } else {
        console.error("Failed to save record in the database.");

        // Extract validation error messages from the API response
        const errorData = await response.json();
        const validationErrors = errorData?.error;

        // Concatenate validation error messages
        const errorMessage =
          "الأخطاء:" + Object.values(validationErrors || {}).join(", ");

        console.error("errorMessage:", errorMessage);

        setSnackbar({ children: errorMessage, severity: "error" });

        // Set the snackbar message for the error
        ///setSnackbar({ children: errorMessage, severity: 'error' });
        ///setSnackbar({ children: errorMessage.replace(/\\n/g, '\n'), severity: 'error' });
      }
    } catch (error) {
      console.error("Error during the API request:", error);

      // Set the snackbar message for the error
      setSnackbar({ children: "حدث خطأ", severity: "error" });
    }
  };

  const SaveConfirmationDialog = ({ open, onClose }) => {
    return (
      <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>Confirm Save</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save this row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const CopyToClipboardButton_handlecopytoken_new = (value, Nom) => {
    // Create a temporary input element
    const tempInput = document.createElement("input");
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand("copy");

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    ////setSnackbar({ children:  `  تم نسخ  ${value}  `, severity: 'success' });
    setSnackbar({
      children: (
        <span
          dangerouslySetInnerHTML={{
            __html: ` تم توليد مفتاح (Token) جديد و نسخه بنجاح  <div dir="ltr">${Nom}</div>`,
          }}
        />
      ),
      severity: "success",
    });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };

  const CopyToClipboardButton_handlecopytoken = (value, Nom) => {
    // Create a temporary input element
    const tempInput = document.createElement("input");
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand("copy");

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    ////setSnackbar({ children:  `  تم نسخ  ${value}  `, severity: 'success' });
    setSnackbar({
      children: (
        <span
          dangerouslySetInnerHTML={{
            __html: ` تم نسخ مفتاح (Token) <div dir="ltr">${Nom}</div>`,
          }}
        />
      ),
      severity: "success",
    });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };

  const handlecopytoken = (id) => {
    const rowtokens = rows.find((row) => row.id === id);
    if (rowtokens) {
      // Extract NomAr value from the row
      const { tokenofapi } = rowtokens;
      const { Nom } = rowtokens;
      CopyToClipboardButton_handlecopytoken(tokenofapi, Nom);
    }
  };


  const handleApiTest = (id) => {
    const rowtokens = rows.find((row) => row.id === id);
    if (rowtokens) {
      // Extract NomAr value from the row
      const { tokenofapi } = rowtokens;
      const { Nom } = rowtokens;
      const { url_function } = rowtokens;
      const fullurl = `${API_BASE_URL}/db/${url_function}`;
        navigate(`/ApiTest?token=${tokenofapi}&apiUrl=${fullurl}`);
       

    }
  };


  const handlenewtoken = async (id, updatedRow) => {
    const rowtokens = rows.find((row) => row.id === id);
    if (!rowtokens) {
      console.error("Row not found for ID:", id);
      return;
    }

    const { tokenofapi, Nom, IDapis_userslist } = rowtokens;

     ///alert(`Row Data After Update: ${JSON.stringify(rowtokens)}`);
     ///alert(`Row Data After Update: ${sessionStorage.getItem("authData_73xJ#E")}`);
    try {
      const response = await fetch(`${API_BASE_URL}/api/updatetoken_apis_userslist`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        },
        body: JSON.stringify(rowtokens),
      });

  

      if (response.ok) {
        const responseData = await response.json(); // Extract JSON from the response
        /// alert(responseData.token); // Alert the token from the response

        setSnackbarOpen({
          children: ` تم توليد مفتاح Token جديد بنجاح، قم بإعادة تسجيل الدخول إلى حسابك `,
          severity: "success",
        });
        //// navigate('/loginEtab');

        ////CopyToClipboardButton_handlecopytoken_new(responseData.token,Nom);
      } else {
        console.error("Failed to save record in the database.");
        const errorData = await response.json();
        const validationErrors = errorData?.error;
        console.error("validationErrors:", validationErrors);
        console.error("validationmessage:", errorData.message);

        const errorMessage =
          "الأخطاء:" + Object.values(validationErrors || {}).join(", ");
        console.error("errorMessage:", errorMessage);
        setSnackbar({ children: errorMessage, severity: "error" });
      }
    } catch (error) {
      console.error("Error during the API request:", error);
      setSnackbar({ children: "حدث خطأ", severity: "error" });
    }
  };

  const [addRecordClicked, setAddRecordClicked] = useState(false);

  useEffect(() => {
    if (addRecordClicked) {
      handleAddRecord();
      setAddRecordClicked(false);
    }
  }, [addRecordClicked]);

  const handleAddClick = () => {
    setIsAdding(true);
    setAddRecordClicked(true);
  };

  const handleAddRecord = () => {
    const id = uuidv4();
    setRows((oldRows) => {
      const newRows = [
        ...oldRows,
        {
          id,
          MatSt: "",
          NIN: "",
          NomAr: "",
          NomFr: "",
          PrenomAr: "",
          PrenomFr: "",
          Email: "",
          Password: "",
          isNew: true,
        },
      ];

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "NomAr" },
      }));
      return newRows;
    });
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  /*

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  
    // Check if a row is in edit mode
    const editedRowId = Object.keys(newRowModesModel).find(
      (id) => newRowModesModel[id]?.mode === GridRowModes.Edit
    );
  
    // If a row is in edit mode, trigger the save operation
    if (editedRowId) {
      handleSaveOperation(editedRowId);
    }
  };

*/
  const preProcessEditCellPropsAr = async (params) => {
    const errorMessage = await validateNamear(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const preProcessEditCellProps = async (params) => {
    const errorMessage = await validateName(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const useStyles = makeStyles({
    cancelBtn: {
      backgroundColor: "#4caf50", // أخضر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#388e3c",
      },
    },
    yesBtn: {
      backgroundColor: "#f44336", // أحمر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#d32f2f",
      },
    },
  });

  const ConfirmationDialog = ({ open, onClose }) => {
    const classes = useStyles();

    return (
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={themetextfieldnew}>
          <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle>
              <Divider>
                <Grid item sx={{ mt: 2 }}>
                  <Typography
                    style={{
                      color: "red",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    تأكيد الحذف <DeleteIcon sx={{ ml: "0px" }} />
                  </Typography>
                </Grid>
              </Divider>
            </DialogTitle>

            <DialogContent>
              <DialogContentText
                sx={{
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 900,
                }}
              >
                هل أنت متأكد من أنك تريد حذف{" "}
                <span style={{ color: "black" }}>
                  {deleted_PrenomAr} {deleted_NomAr}؟
                </span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onClose(false)}
                className={classes.cancelBtn}
                color="primary"
                sx={{
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 900,
                  backgroundColor: "#4caf50", // أخضر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#388e3c",
                  },
                }}
              >
                إلغاء
              </Button>
              <Button
                onClick={() => onClose(true)}
                className={classes.yesBtn}
                color="primary"
                autoFocus
                sx={{
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 900,
                  backgroundColor: "#f44336", // أحمر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
              >
                نعم
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </CacheProvider>
    );
  };

  const handleExportExcel = () => {
    // Extracting only the 'name' field and 'headerName' from columns
    const relevantColumns = columns.map((col) => ({
      name: col.field,
      headerName: col.headerName,
    }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      rows.map((row) => {
        const newRow = {};
        relevantColumns.forEach((col) => {
          newRow[col.headerName] = row[col.name];
        });
        return newRow;
      })
    );

    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: "Calibri",
        sz: 11,
        bold: true,
        color: {
          rgb: "FFFFAA00",
        },
      },
    };

    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
    const timestamp = Math.floor(Date.now() / 1000);

    XLSX.writeFile(wb, `Moutakawin_${timestamp}.xlsx`);
  };

  /*
  id: item.idapis_userlist_functions,
          id_rows: index + 1,
          idapis_userlist_functions: item.idapis_userlist_functions,
          Nom: item.Nom,
          description_function: item.description_function,
          url_function: item.url_function,
          tokenofapi: item.tokenofapi,
          apis_functions.Nom as Nomfunction, 
          IDapis_userslist: item.IDapis_userslist,
          idapis_functions: item.idapis_functions,
          nomUser: item.nomUser,
          actions: '',
  */

  const columns = [
    {
      field: "id_rows",
      headerName: "ترقيم",
      minWidth: 70,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    },

    {
      field: "Nomfunction",
      headerName: "تسمية المفتاح",
      flex: 1,
      minWidth: 500,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    },

    {
      field: "description_function",
      headerName: "الوصف",
      flex: 1,
      minWidth: 500,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    },

    ,
    {
      field: "url_function",
      headerClassName: "super-app-theme--header",
      headerName: "الرابط URL",
      cellClassName: "cell-editable", // Add a custom header class for this column
      editable: false, // Adjust based on your requirements
      flex: 1, // Allow column to grow/shrink
      minWidth: 1000, // Set a minimum width
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <span>{`${API_BASE_URL}/db/${params.value}`}</span>
      ),
    },

    ,
    {
      field: "tokenofapi",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Token d'accès",
      flex: 1,
      editable: false, // Adjust based on your requirements
      minWidth: 450,
      align: "center",
      headerAlign: "center",
      getActions: ({ id, params }) => {
        const isNumber = !isNaN(Number(id)); // Check if the ID is a number

        return [
          <Button
            key="copy"
            disabled={!isNumber}
            onClick={() => handlecopytoken(id)}
            variant="contained"
            endIcon={
              <ContentCopyIcon
                sx={{ mr: "2px", ml: "-2px", transform: "rotate(0deg)" }}
              />
            }
            style={{
              backgroundColor: "green",
              width: "fit-content",
              marginLeft: "5px",
            }}
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 12,
                fontWeight: 900,
              }}
            >
              نسخ الحالي
            </Typography>
          </Button>,
          <Button
          key="copy"
          disabled={!isNumber}
          onClick={() => handleApiTest(id)}
          variant="contained"
          endIcon={
            <ManageSearchIcon
              sx={{ mr: "2px", ml: "-2px", transform: "rotate(0deg)" }}
            />
          }
          style={{
            backgroundColor: "grey",
            width: "fit-content",
            marginLeft: "5px",
          }}
        >
          <Typography
            sx={{
              color: "#FFFFFF",
              fontFamily: '"Tajawal", sans-serif',
              fontSize: 12,
              fontWeight: 900,
            }}
          >
          اختبار
          </Typography>
        </Button>,
          <Button
            key="cancel"
            disabled={!isNumber}
            onClick={() => handlenewtoken(id)}
            variant="contained"
            endIcon={
              <ReplayIcon
                sx={{ mr: "2px", ml: "-2px", transform: "rotate(0deg)" }}
              />
            }
            style={{ width: "fit-content" }}
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 12,
                fontWeight: 900,
              }}
            >
              توليد جديد
            </Typography>
          </Button>,
        ];
      },
    },
  ];

  function generateRandomString(length = 6) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const CopyToClipboardButton = (value) => {
    // Create a temporary input element
    const tempInput = document.createElement("input");
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand("copy");

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    setSnackbar({ children: `  تم نسخ  ${value}  `, severity: "success" });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };

  useEffect(() => {
    if (!loadingauth) {
      if (IDapis_userslist === null) {
        // Navigate to the 404 page if any value is missing
        navigate("/404");
        return;
      }
    }
  }, [
    IDetablissement,
    IDDFEP,
    IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    navigate,

    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep,
  ]); // Add navigate to dependencies

  if (loadingauth) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress color="success" />
      </Box>
    );
  }

  return (
    <>
      <Header
        IDetablissement={IDetablissement}
        IDDFEP={IDDFEP}
        IDNature_etsF={IDNature_etsF}
        IDCandidat={IDCandidat}
        NumSem={NumSem}
        IDapis_userslist={IDapis_userslist}
        loadingauth={loadingauth}
        Singup_active={Singup_active}
        download_cert_scol_active={download_cert_scol_active}
        EditingInfo_active={EditingInfo_active}
        Eval_notes_active={Eval_notes_active}
        download_cert_inscription_active={download_cert_inscription_active}
        Singup_active_Dfep={Singup_active_Dfep}
        download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
        EditingInfo_active_Dfep={EditingInfo_active_Dfep}
        Eval_notes_active_Dfep={Eval_notes_active_Dfep}
        download_cert_inscription_active_Dfep={
          download_cert_inscription_active_Dfep
        }
        Singup_active_Mfep={Singup_active_Mfep}
        download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
        EditingInfo_active_Mfep={EditingInfo_active_Mfep}
        Eval_notes_active_Mfep={Eval_notes_active_Mfep}
        download_cert_inscription_active_Mfep={
          download_cert_inscription_active_Mfep
        }
      />

      <Box
        sx={{
          height: 500,
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <div style={{ height: 400, width: "100%" }}>
          <Box
            sx={{
              "& .super-app-theme--header": {
                fontFamily: "Amiri",
                fontSize: "20px",
                fontWeight: "bold", // Make the font bold
                backgroundColor: "#B9D3EE",
                borderRight: "1px solid #ccc", // Add this line to apply right border to the cell
              },
            }}
          >
            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={themetextfieldnew}>
                {!!snackbar && (
                  <Snackbar
                    open
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                    sx={{
                      marginTop: 12,
                    }}
                  >
                    <Alert
                      {...snackbar}
                      onClose={handleCloseSnackbar}
                      sx={{
                        display: "flex",
                        fontFamily: "Amiri",
                        fontSize: "20px",
                        "& .MuiAlert-icon": { marginLeft: "10px" },
                        "& .MuiAlert-action": { marginRight: "8px" },
                      }} // Adjust padding here
                    />
                  </Snackbar>
                )}

                <Grid container>
                  <Grid item sx={{ width: "100%" }}>
                    <Divider sx={{ mt: "20px" }}>
                      <Grid item>
                        <Typography
                          style={{
                            color: "#191970",
                            fontFamily: '"Tajawal", sans-serif',
                            fontSize: 25,
                            fontWeight: 700,
                            marginBottom: "25px",
                          }}
                          variant="h3"
                          gutterBottom
                        >
                          <ApiIcon sx={{ mr: "2px", fontSize: 30 }} />
                          جدول مفاتيح API &nbsp; ( {Nomapis_userslist} )
                        </Typography>
                      </Grid>
                    </Divider>
                  </Grid>

                  {!!snackbarOpen && (
                    <Snackbar
                      open
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      onClose={handleCloseSnackbarnew}
                      autoHideDuration={6000}
                      sx={{
                        marginTop: 12,
                      }}
                    >
                      <Alert
                        {...snackbarOpen}
                        onClose={handleCloseSnackbarnew}
                        sx={{
                          display: "flex",
                          fontFamily: "Amiri",
                          fontSize: "20px",
                          "& .MuiAlert-icon": { marginLeft: "10px" },
                          "& .MuiAlert-action": { marginRight: "8px" },
                        }} // Adjust padding here
                      />
                    </Snackbar>
                  )}

                  {loadingdata_mfep ? (
                    <>
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress color="success" />
                      </Box>
                    </>
                  ) : null}

                  <DataGrid
                    locale="ar-SD"
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    getRowId={(row) => row.id}
                    getRowClassName={(params) =>
                      parseInt(params.row.id_rows, 10) % 2 === 0
                        ? "even-row"
                        : "odd-row"
                    }
                    slots={{
                      toolbar: CustomToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        setRows,
                        setRowModesModel,
                        columns,
                        handleExportExcel,
                      },
                    }}
                    sx={{
                      boxShadow: 2,
                      border: 2,
                      borderColor: "primary.light",

                      "& .MuiDataGrid-cell": {
                        fontFamily: "Amiri",
                        fontSize: "20px",
                        textAlign: "center",
                        backgroundColor: "rgba(255,255,255,0.65)",
                        borderRight: "1px solid #ccc", // Add this line to apply right border to the cell
                      },

                      "& .MuiDataGrid-row:hover": {
                        backgroundColor: "#2b84dd", // Background color when row is hovered
                      },

                      "& .even-row": {
                        backgroundColor: "#2b84dd61", // Background color for even rows
                      },
                      "& .odd-row": {
                        backgroundColor: "#ffffff", // Background color for odd rows
                      },
                    }}
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 25, page: 0 },
                      },
                    }}
                  />
                </Grid>
              </ThemeProvider>
            </CacheProvider>
          </Box>

          <Footer />
        </div>
        <ConfirmationDialog
          open={isConfirmationOpen}
          onClose={handleConfirmationResponse}
        />
        <SaveConfirmationDialog
          open={isSaveConfirmationOpen}
          onClose={handleSaveConfirmationResponse}
        />
      </Box>
    </>
  );
}

export default ShowApiList;
