import React, { useState, useEffect, useRef } from 'react';

import { Typography, Box } from '@mui/material';

import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import useAuthentication_etab from './useAuthentication_etab';

import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  arSD,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import ReplayIcon from '@mui/icons-material/Replay';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { CacheProvider } from "@emotion/react";



import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import XLSX from 'sheetjs-style';
import Footer from './Footer';
import Header from './Header';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import createCache from "@emotion/cache";

import '../containers/amirifont.css'; // Import the font CSS file
import Divider from '@mui/material/Divider';
import LockIcon from '@mui/icons-material/Lock';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

import { makeStyles } from "@material-ui/core/styles";

const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme({
  direction: 'rtl',
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: hoverColor
        },
        "&$focused $notchedOutline": {
          borderColor: focusColor
        }
      },
      notchedOutline: {
        borderColor: defaultColor
      }
    }
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  }
}, arSD);

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({

  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

async function loginUser(email, password) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, password: password }),
    });

    if (!response.ok) {
      throw new Error('Invalid credentials');
    }

    const data = await response.json();
    const token = data.token;
    return token;
  } catch (error) {
    console.error('Error logging in:', error.message);
    return null;
  }
}


const StyledBox = styled('div')(({ theme }) => ({
  height: 400,
  width: '100%',
  '& .MuiDataGrid-cell--editable': {
    backgroundColor: theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
    color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
  },
}));

let rowsdata = [];
let promiseTimeout;
function validateName(username) {

  const alphabetRegex = /^[a-zA-Z\s]+$/;
  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      const isValid = alphabetRegex.test(username);
      resolve(isValid ? null : 'Username must contain only alphabets (a to z).');
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

let promiseTimeoutar;
function validateNamear(username) {
  const arabicAlphabetRegex = /^[\u0600-\u06FF\s]+$/;

  return new Promise((resolve) => {
    promiseTimeoutar = setTimeout(() => {
      const isValid = arabicAlphabetRegex.test(username);
      resolve(isValid ? null : 'Username must contain only Arabic alphabets.');
    }, Math.random() * 500 + 100); // simulate network latency
  });
}


const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEditName(params) {
  return <NameEditInputCell {...params} />;
}



const apiUrldfep = `${API_BASE_URL}/api/getlistofalldfep`;

function CustomToolbar({ setRows, setRowModesModel, columns, handleExportExcel, validation_multiple, selectedRows }) {
  return (

    <GridToolbarContainer style={{ fontFamily: 'Amiri', fontSize: '20px' }} >

      {/* 
       {selectedRows.length ? (

        <Button 
          onClick={validation_multiple}
      variant="contained" 
      endIcon={<DoneAllIcon sx={{ fontSize: 40 }} />} 
      style={{ backgroundColor: 'green', width: 'fit-content' }}
    >
      <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 20, fontWeight: 900 }}>
      مصادقة ( {selectedRows.length} محدد )
      </Typography>
    </Button>
    ) : null}

       */}

      <GridToolbarColumnsButton style={{ fontFamily: 'Amiri', fontSize: '20px' }} />
      <GridToolbarFilterButton style={{ fontFamily: 'Amiri', fontSize: '20px' }} />
      <GridToolbarDensitySelector style={{ fontFamily: 'Amiri', fontSize: '20px' }} />
      <Button style={{ fontFamily: 'Amiri', fontSize: '20px' }} onClick={handleExportExcel} color="primary">
        تنزيل كملف إكسال
      </Button>
      <GridToolbarExport style={{ fontFamily: 'Amiri', fontSize: '20px' }} />

      <GridToolbarQuickFilter
        style={{ fontFamily: 'Amiri', fontSize: '20px', marginRight: '25px' }}
        inputProps={{ style: { fontFamily: 'Amiri', fontSize: '20px' } }}
      />



    </GridToolbarContainer>
  );
}

let options_Wly = [];
let options_sess = [];
let Wlylists = [];


function OffersMfep() {

  const { IDetablissement, IDDFEP, IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  } = useAuthentication_etab();


  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [isAdding, setIsAdding] = React.useState(false);
  const [deletedRowId, setDeletedRowId] = useState(null);
  const [idforsave, setidforsave] = useState(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
  const [editedValue, setEditedValue] = useState('');

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const [valuegenpw, setvaluegenpw] = useState('');
  const [deleted_NomAr, setDeleted_NomAr] = useState(null);
  const [deleted_PrenomAr, setDeleted_PrenomAr] = useState(null);
  const [loadingdata_mfep, setloadingdata_mfep] = useState(false);
  const [uniqueNomEtValues, setUniqueNomEtValues] = useState([]);
  const [selectedNomEtValue, setSelectedNomEtValue] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [gridKey, setGridKey] = useState(0); // Add a key for the DataGrid

  const [IDDFEP_selected, setIDDFEP_selected] = useState(1);
  const [IDsession_selected, setIDsession_selected] = useState(0);

  const [selectedRows, setSelectedRows] = React.useState([]);

  const editedValueRef = useRef(editedValue);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption_session, setSelectedOption_session] = useState(null);

  const [options_Wlylist, setoptions_Wlylist] = React.useState([]);
  const [options_session, setoptions_session] = React.useState([]);


  const useStyles_listdfep = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
        fontFamily:
          '"Tajawal", sans-serif',
        fontSize: 18,
        direction: "rtl"
      }
    },
    inputRoot: {

      color: "#191970",


      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        fontFamily: '"Tajawal", sans-serif',
        fontWeight: 700,
        color: "#191970",
        // Default left padding is 6px
        paddingLeft: 26
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#add8e6"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "red"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "purple"
      }
    }
  }));

  const classes_listdfep = useStyles_listdfep();


  const onwlyChanged_session = (event, values) => {

    if (values) {
      setSelectedOption_session(values);
      setIDsession_selected(values.value);
    } else {
      return;
    }


  }

  const onwlyChanged = (event, values) => {

    if (values) {
      setSelectedOption(values);
      setIDDFEP_selected(values.value);
    } else {
      return;
    }


  }




  // Update the ref whenever editedValue changes
  useEffect(() => {
    editedValueRef.current = editedValue;
  }, [editedValue]);

  const navigate = useNavigate();




  const apiUrl_session = `${API_BASE_URL}/api/getlistofallsessionMfep`;

  // Fetch data from the API when the component mounts
  useEffect(() => {


    setloadingdata_mfep(true);
    fetch(apiUrl_session,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then(response => response.json())
      .then(data => {
        // Assuming 'data' is an array of objects
        const Wlylists = data.data; // Extracting the relevant data

        /////////////Wilayets///////////////////

        const WlyOnly = Wlylists.filter(Wlylist => Wlylist.Nom !== null);

        // Sorting in descending order (from A to Z)
        /* options_sess = WlyOnly.sort((a, b) => Number(a.IDSession) - Number(b.IDSession))
             .map((Wlylist, i) => ({
                 value: Wlylist.IDSession,
                 label: Wlylist.Nom + ' | ' + Wlylist.NomFr,
                 indexvalue: i
             }));*/


        // Sorting in descending order (from Z to A)
        options_sess = WlyOnly
          .map((Wlylist, i) => ({
            value: Wlylist.IDSession,
            label: Wlylist.Nom + ' | ' + Wlylist.NomFr,
            indexvalue: i
          }));

        ///// options_sess.unshift({ value: 0, label: 'كل الدورات', indexvalue: -1 });

        setoptions_session(options_sess);

        /// console.log('options_Wly[0].value:'+options_sess[0].value);
        setloadingdata_mfep(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });


  }, []);

  useEffect(() => {
    if (options_session && options_session.length > 0) {
      setSelectedOption_session(options_session[0]);
      setIDsession_selected(options_session[0].value);
    }

  }, [options_session]);



  // Fetch data from the API when the component mounts
  useEffect(() => {


    setloadingdata_mfep(true);
    fetch(apiUrldfep,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then(response => response.json())
      .then(data => {
        // Assuming 'data' is an array of objects
        const Wlylists = data.data; // Extracting the relevant data

        /////////////Wilayets///////////////////

        const WlyOnly = Wlylists.filter(Wlylist => Wlylist.Nom !== null);

        // Sorting
        options_Wly = WlyOnly.sort((a, b) => Number(a.IDDFEP) - Number(b.IDDFEP))
          .map((Wlylist, i) => ({
            value: Wlylist.IDDFEP,
            label: Wlylist.IDDFEP + '- ' + Wlylist.Nom,
            indexvalue: i
          }));

        ////options_Wly.unshift({ value: 99, label: 'كل المديريات', indexvalue: -1 });

        setoptions_Wlylist(options_Wly);

        /// console.log('options_Wly[0].value:'+options_Wly[0].value);
        setloadingdata_mfep(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });





  }, []);

  useEffect(() => {
    if (options_Wlylist && options_Wlylist.length > 0) {
      setSelectedOption(options_Wlylist[0]);
      setIDDFEP_selected(options_Wlylist[0].value);
    }

  }, [options_Wlylist]);

  const apiUrl = `${API_BASE_URL}/api/getoffer_idsession_iddfep`;

  useEffect(() => {


    if(IDDFEP_selected==null || IDDFEP_selected==undefined || !Number(IDDFEP_selected)>0)
    {
      return;
    }

    if(IDsession_selected==null || IDsession_selected==undefined || !Number(IDsession_selected)>0)
    {
      return;
    }

    setloadingdata_mfep(true);
    setRows([]); // Clear rows before setting new rows
    setFilteredRows([]);

    fetch(apiUrl + '/' + IDsession_selected + '/' + IDDFEP_selected,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then((response) => response.json())
      .then((data) => {
        const apiRows = data.data.map((item, index) => ({

          id: item.IDOffre,
          id_rows: index + 1,
          IDOffre: item.IDOffre,
          IDSession: item.IDSession,
          IDSpecialite: item.IDSpecialite,
          IDMode_formation: item.IDMode_formation,
          DateD: item.DateD,
          DateF: item.DateF,
          IDEts_Form: item.IDEts_Form,
          ValidationDfp: item.ValidationDfp,
          ValidDfp: item.ValidDfp,
          ValideCentral: item.ValideCentral,
          Valide: item.Valide,
          code_etab: item.code_etab,
          Nom_Et: item.Nom_Et,
          Nom_NomFr_session: item.Nom_NomFr_session,
          Nom_NomFr_CodeSpec_NbrSem_specialite: item.Nom_NomFr_CodeSpec_NbrSem_specialite,
          code_Nom_etablissement: item.code_Nom_etablissement,
          Nom_NomFr_mode_formation: item.Nom_NomFr_mode_formation,


          /*
                  offre.*, 
                  `session`.Nom AS Nom_session, 
                  `session`.NomFr AS NomFr_session, 
                  CONCAT(`session`.Nom, ' | ', `session`.NomFr) AS Nom_NomFr_session,
                  specialite.CodeSpec, 
                  specialite.Nom AS Nom_specialite, 
                  specialite.NomFr AS NomFr_specialite, 
                  specialite.NbrSem, 
                  CONCAT(specialite.CodeSpec, ' | ', specialite.Nom, ' | ', specialite.NomFr, ' ( ', specialite.NbrSem, ' S ) ') AS Nom_NomFr_CodeSpec_NbrSem_specialite,
                  etablissement.IDetablissement, 
                  etablissement.Nom AS Nom_etablissement, 
                  etablissement.nomFr AS nomFr_etablissement, 
                  etablissement.IDDFEP,
                  etablissement.`Code` as code_etab,
                  etablissement.Nom as 	Nom_Et,
                  CONCAT(etablissement.`Code`, ' | ', etablissement.Nom) AS code_Nom_etablissement,  
                  mode_formation.Nom AS Nom_mode_formation, 
                  mode_formation.NomFr AS NomFr_mode_formation,
                  CONCAT(mode_formation.`Nom`, ' | ', mode_formation.NomFr) AS Nom_NomFr_mode_formation
          */
        }));
        setRows(apiRows);
        console.log('apiRows:', apiRows);
        ///rowsdata=apiRows;
        setFilteredRows(apiRows);
        // Extract unique 'Nom_Et' values
        /// const uniqueValues = [...new Set(apiRows.map(row => row.code_etab))];


        const uniqueCodeEtabs = [...new Set(apiRows.map(row => row.code_etab))];
        const uniqueValues = uniqueCodeEtabs.map((code_etab, index) => ({
          key: index,
          value: code_etab,
          label: code_etab + ' | ' + apiRows.find(row => row.code_etab === code_etab).Nom_Et
        }));

        // Adding the desired object at the beginning of uniqueValues
        uniqueValues.unshift({
          key: 0,
          value: 999,
          label: 'كل المؤسسات'
        });


        setUniqueNomEtValues(uniqueValues);


        /////console.log('apiRows: ', apiRows);
        ///  console.log('uniqueValues: ',uniqueNomEtValues.length);
        //setSelectedNomEtValue(999);
        setloadingdata_mfep(false);


      })
      .catch((error) => console.error('Error fetching data:', error));


  }, [IDsession_selected, IDDFEP_selected]);


  useEffect(() => {
    console.log('uniqueValues length: ', uniqueNomEtValues.length);
  }, [uniqueNomEtValues]);


  useEffect(() => {

    setFilteredRows([]);
    // Filter rows based on selectedNomEtValue
    if (selectedNomEtValue && selectedNomEtValue.value === 999) {
      setFilteredRows(rows);
    } else {

      const filteredData = rows.filter(row => row.code_etab === selectedNomEtValue.value);
      /// alert(JSON.stringify(filteredData));
      setFilteredRows(filteredData);
    }

    // Increment the key to force a remount of the DataGrid
    setGridKey(prevKey => prevKey + 1);

  }, [selectedNomEtValue]);



  const handleNomEtChange = (event, newValue) => {

    if (newValue) {

      // Handle the change according to newValue
      setSelectedNomEtValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state

      // You can perform further actions based on the selected value if needed
      console.log("Selected Nom Et Value:", newValue);
    }

  };


  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut || params.reason === GridRowEditStopReasons.commit) {
      event.defaultMuiPrevented = true;
      const editedRow = rows.find((row) => row.id === params.id);

      if (editedRow) {
        /// alert(`Editing stopped for ID: ${editedRow.id}`);
        // Trigger the save operation for the edited row
        handleSaveOperation(editedRow.id);
      }
    }
  };




  const handleEditClick = (id) => () => {
    const rowToEdit = rows.find((row) => row.id === id);
    const editedValues = {};

    // Copy all fields from the row to the editedValues object
    Object.keys(rowToEdit).forEach((field) => {
      editedValues[field] = rowToEdit[field];
    });

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setEditedValue({ ...editedValue, [id]: editedValues });
  };



  const [idsaved, setidsaved] = useState(0);


  const [confirmationTrigger, setConfirmationTrigger] = useState(false);


  const handleSaveClick = (id) => () => {

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    //setidforsave(id);  
    ///setConfirmationTrigger(true); // Set the trigger for confirmation

  };

  useEffect(() => {
    if (confirmationTrigger) {
      setSaveConfirmationOpen(true);
      setConfirmationTrigger(false); // Reset the trigger after executing
    }
  }, [confirmationTrigger, rowModesModel]);



  const handleDeleteClick = (id) => () => {

    const rowToDelete = rows.find((row) => row.id === id);
    if (rowToDelete) {
      // Extract NomAr value from the row
      const { ID } = rowToDelete;
      const { NomAr } = rowToDelete;
      const { PrenomAr } = rowToDelete;
      // Open the confirmation dialog
      setConfirmationOpen(true);
      setDeletedRowId(ID);
      setDeleted_NomAr(NomAr);
      setDeleted_PrenomAr(PrenomAr);


    }


  };




  const handleConfirmationResponse = async (confirmed) => {
    return;
    // Close the confirmation dialog
    setConfirmationOpen(false);

    if (confirmed) {
      const idToDelete = deletedRowId;
      setDeletedRowId(null); // Reset deletedRowId to avoid multiple deletions

      try {
        // Send the API request to delete the row from the Laravel backend
        const response = await fetch(`${API_BASE_URL}/api/deleteRow_fromCand/${idToDelete}`, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        });

        if (response.ok) {
          // If the API request is successful, update the local state to remove the row
          setRows((prevRows) => prevRows.filter((row) => row.id !== idToDelete));
          ////////console.log('Row deleted successfully from Laravel backend.');
          setSnackbar({ children: '  تم الحذف بنجاح  ', severity: 'success' });

        } else {
          console.error('Failed to delete row from Laravel backend.');
          // Handle the error or display a notification to the user
        }
      } catch (error) {
        console.error('Error during the API request:', error);
        // Handle the error or display a notification to the user
      }
    } else {
      // User clicked "Cancel," do nothing
    }
  };

  const handleSaveConfirmationResponse = async (confirmed) => {
    // Close the confirmation dialog
    setSaveConfirmationOpen(false);

    if (confirmed) {
      const idforsavea = idforsave;
      handleSaveOperation(idforsavea);
      setidforsave(null);
    } else {
      // User clicked "Cancel," do nothing
    }
  };



  const processRowUpdate = async (newRow) => {
    // Check if the row is new
    const isNewRow = rows.find((row) => row.id === newRow.id)?.isNew;

    // Preserve the original isNew value if it exists
    const updatedRow = { ...newRow, isNew: isNewRow };

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    // Display an alert with the updated row data and id
    ///alert(`Row Data After Update for ID ${newRow.id}: ${JSON.stringify(updatedRow)}`);

    try {
      // Call handleSaveOperation with the id
      await handleSaveOperation(newRow.id, updatedRow);
    } catch (error) {
      console.error('Error during handleSaveOperation:', error);
    }

    return updatedRow;
  };





  const handleSaveOperation = async (id, updatedRow) => {

    const editedRow = updatedRow;
    if (!editedRow) {
      console.error('Row not found for ID:', id);
      return;
    }

    ////alert(`Row Data After Update: ${JSON.stringify(editedRow)}`);


    try {
      let response;


      // If the row is not new, it means it's already in the database, so update it
      response = await fetch(`${API_BASE_URL}/api/updateRowoffermfep/${id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', // Specify the content type
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        },
        body: JSON.stringify(editedRow),
      });


      if (response.ok) {
        setRows((prevRows) =>
          prevRows.map((row) => {
            if (row.id === id) {
              // If the row being updated has the same id as the edited row
              // Update all fields except for the password
              return { ...row, isNew: false, Password: '****' };
            } else {
              // For other rows, keep them unchanged
              return row;
            }
          })
        );


        // Set the snackbar message
        setSnackbar({ children: '  تم الحفظ  ', severity: 'success' });

        // Set the row mode to view after saving
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
      } else {
        console.error('Failed to save record in the database.');

        // Extract validation error messages from the API response
        const errorData = await response.json();
        const validationErrors = errorData?.error;

        // Concatenate validation error messages
        const errorMessage =
          'الأخطاء:' +
          Object.values(validationErrors || {}).join(', ');

        console.error('errorMessage:', errorMessage);

        setSnackbar({ children: validationErrors, severity: 'error' });

      }
    } catch (error) {
      console.error('Error during the API request:', error);

      // Set the snackbar message for the error
      setSnackbar({ children: 'حدث خطأ', severity: 'error' });
    }



  };




  const SaveConfirmationDialog = ({ open, onClose }) => {
    return (
      <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>Confirm Save</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save this row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };





  const [addRecordClicked, setAddRecordClicked] = useState(false);

  useEffect(() => {
    if (addRecordClicked) {
      handleAddRecord();
      setAddRecordClicked(false);
    }
  }, [addRecordClicked]);

  const handleAddClick = () => {
    setIsAdding(true);
    setAddRecordClicked(true);
  };



  const handleAddRecord = () => {
    const id = uuidv4();
    setRows((oldRows) => {
      const newRows = [
        ...oldRows,
        { id, MatSt: '', NIN: '', NomAr: '', NomFr: '', PrenomAr: '', PrenomFr: '', Email: '', Password: '', isNew: true },
      ];



      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'NomAr' },
      }));
      return newRows;
    });
  };


  const handleRowModesModelChange = (newRowModesModel) => {

    setRowModesModel(newRowModesModel);
  };


  const preProcessEditCellPropsAr = async (params) => {
    const errorMessage = await validateNamear(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const preProcessEditCellProps = async (params) => {
    const errorMessage = await validateName(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };


  const useStyles = makeStyles({
    cancelBtn: {
      backgroundColor: "#4caf50", // أخضر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#388e3c",
      },
    },
    yesBtn: {
      backgroundColor: "#f44336", // أحمر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#d32f2f",
      },
    },
  });

  const ConfirmationDialog = ({ open, onClose }) => {
    const classes = useStyles();

    return (
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={themetextfieldnew}>


          <Dialog
            open={open} onClose={() => onClose(false)}>
            <DialogTitle>
              <Divider >
                <Grid item sx={{ mt: 2 }} >
                  <Typography
                    style={{
                      color: "red",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    تأكيد الحذف <DeleteIcon sx={{ ml: '0px' }} />
                  </Typography>
                </Grid>
              </Divider>


            </DialogTitle>



            <DialogContent>
              <DialogContentText
                sx={{ fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }}>
                هل أنت متأكد من أنك تريد حذف <span style={{ color: 'black' }}>{deleted_PrenomAr} {deleted_NomAr}؟</span>

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onClose(false)}
                className={classes.cancelBtn}
                color="primary"
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#4caf50", // أخضر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#388e3c",
                  },
                }}
              >
                إلغاء
              </Button>
              <Button
                onClick={() => onClose(true)}
                className={classes.yesBtn}
                color="primary"
                autoFocus
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#f44336", // أحمر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
              >
                نعم
              </Button>
            </DialogActions>
          </Dialog>

        </ThemeProvider>
      </CacheProvider>
    );
  };


  const validation_multiple = () => {
    // Alert how many rows are selected
    alert(`Number of selected rows: ${selectedRows.length}`);

  }


  const handleExportExcel = () => {
    // Extracting only the 'name' field and 'headerName' from columns
    const relevantColumns = columns.map(col => ({ name: col.field, headerName: col.headerName }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows.map(row => {
      const newRow = {};
      relevantColumns.forEach(col => {
        newRow[col.headerName] = row[col.name];
      });
      return newRow;
    }));

    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: 'Calibri',
        sz: 11,
        bold: true,
        color: {
          rgb: 'FFFFAA00',
        },
      },
    };

    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    const timestamp = Math.floor(Date.now() / 1000);

    XLSX.writeFile(wb, `Moutakawin_${timestamp}.xlsx`);
  };

  /*
  
  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows, {
      header: columns.map((col) => col.headerName),
    });
  
    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: 'Calibri',
        sz: 11,
        bold: true,
        color: {
          rgb: 'FFFFAA00',
        },
      },
    };
  
    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    XLSX.writeFile(wb, 'exported_data.xlsx');
  };*/

  const columns = [

    /*
         code_etab:item.code_etab,
        Nom_Et:item.Nom_Et,
        Nom_NomFr_session:item.Nom_NomFr_session,
        Nom_NomFr_CodeSpec_NbrSem_specialite:item.Nom_NomFr_CodeSpec_NbrSem_specialite,
        code_Nom_etablissement:item.code_Nom_etablissement,
        Nom_NomFr_mode_formation:item.Nom_NomFr_mode_formation,
        */

    {
      field: 'id_rows',
      headerName: 'ترقيم',
      minWidth: 40,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'IDOffre',
      headerName: 'IDOffre',
      minWidth: 55,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'code_Nom_etablissement',
      headerName: 'المؤسسة',
      flex: 1,
      minWidth: 300,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',
    },

    {
      field: 'Nom_NomFr_CodeSpec_NbrSem_specialite',
      headerName: 'التخصص',
      flex: 1,
      minWidth: 300,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',
    },

    {
      field: 'Nom_NomFr_mode_formation',
      headerName: 'نمط التكوين',
      flex: 1,
      minWidth: 250,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',
    },

    {
      field: 'DateD',
      headerName: 'DateD',
      minWidth: 140,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'DateF',
      headerName: 'DateF',
      minWidth: 140,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'ValideCentral',
      headerName: 'مصادقة الوزارة',
      minWidth: 180,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => (
        <FormControlLabel
          control={<IOSSwitch
            sx={{ m: 1 }}
            checked={Number(row.ValideCentral) === 1 ? true : false}
            onChange={() => onchangeValideCentral(row)}
          />}
          label={<span style={{
            color: "#191970",
            fontFamily: '"Tajawal", sans-serif',
            fontSize: 16,
            fontWeight: 900,
          }}></span>}
        />
      ),
    }


    ,

  ];

  function generateRandomString(length = 8) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }




  const CopyToClipboardButton = (value) => {
    // Create a temporary input element
    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    setSnackbar({ children: `  تم نسخ  ${value}  `, severity: 'success' });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };



  function onchangeValideCentral(row) {

    if (Number(row.ValideCentral) === 1) {
      /// alert(0);
      row.ValideCentral = 0; // Set ValideCentral to 0
    } else if (Number(row.ValideCentral) === 0) {
      //// alert(1);
      row.ValideCentral = 1; // Set ValideCentral to 1
    }

    // Update the state of the row data
    setRows(prevRows => {
      // Create a new array by mapping over the previous rows
      return prevRows.map(prevRow => {
        // If the ID of the current row matches the modified row, return the modified row
        if (prevRow.id === row.id) {
          return row;
        }
        // Otherwise, return the unchanged row
        return prevRow;
      });
    });

    handleSaveOperation(row.id, row);

  }




  useEffect(() => {
    if (!loadingauth) {
      if (
        IDetablissement === null ||
        IDDFEP === null ||
        IDNature_etsF === null ||
        Number(IDNature_etsF) !== 1
      ) {
        // Navigate to the 404 page if any value is missing
        navigate('/404');
        return;
      }


    }
  }, [IDetablissement, IDDFEP, IDNature_etsF, IDCandidat, NumSem, IDapis_userslist,
    loadingauth, navigate,

    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  ]); // Add navigate to dependencies

  if (loadingauth) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress color="success" />
      </Box>
    );
  }


  return (
    <>

      <Header
        IDetablissement={IDetablissement}
        IDDFEP={IDDFEP}
        IDNature_etsF={IDNature_etsF}
        IDCandidat={IDCandidat}
        NumSem={NumSem}
        IDapis_userslist={IDapis_userslist}
        loadingauth={loadingauth}
        Singup_active={Singup_active}
        download_cert_scol_active={download_cert_scol_active}
        EditingInfo_active={EditingInfo_active}
        Eval_notes_active={Eval_notes_active}
        download_cert_inscription_active={download_cert_inscription_active}
        Singup_active_Dfep={Singup_active_Dfep}
        download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
        EditingInfo_active_Dfep={EditingInfo_active_Dfep}
        Eval_notes_active_Dfep={Eval_notes_active_Dfep}
        download_cert_inscription_active_Dfep={download_cert_inscription_active_Dfep}
        Singup_active_Mfep={Singup_active_Mfep}
        download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
        EditingInfo_active_Mfep={EditingInfo_active_Mfep}
        Eval_notes_active_Mfep={Eval_notes_active_Mfep}
        download_cert_inscription_active_Mfep={download_cert_inscription_active_Mfep}
      />


      <Box
        sx={{
          height: 500,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >

        <div style={{ height: 400, width: '100%' }}>




          <Box
            sx={{
              '& .super-app-theme--header': {
                fontFamily: 'Amiri',
                fontSize: '20px',
                fontWeight: 'bold', // Make the font bold
                backgroundColor: '#B9D3EE',
                borderRight: '1px solid #ccc', // Add this line to apply right border to the cell
              }

            }}

          >

            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={themetextfieldnew}>



                {!!snackbar && (
                  <Snackbar
                    open
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                    sx={{
                      marginTop: 12
                    }}
                  >
                    <Alert
                      {...snackbar}
                      onClose={handleCloseSnackbar}
                      sx={{
                        display: 'flex', fontFamily: 'Amiri', fontSize: '20px', '& .MuiAlert-icon': { marginLeft: '10px' }
                        , '& .MuiAlert-action': { marginRight: '8px' }
                      }} // Adjust padding here
                    />
                  </Snackbar>

                )}



                <Grid container   >











                  <Grid item sx={{ width: "100%" }}>

                    <Divider sx={{ mt: '20px' }}>
                      <Grid item  >
                        <Typography
                          style={{
                            color: "#191970",
                            fontFamily: '"Tajawal", sans-serif',
                            fontSize: 25,
                            fontWeight: 700,
                            marginBottom: '25px'
                          }}
                          variant="h3" gutterBottom>
                          <EventRepeatIcon sx={{ mr: '5px' }} />
                          العروض
                        </Typography>
                      </Grid>

                    </Divider>


                  </Grid>


                  <Grid container alignItems="center" align="center" justifyContent="center" sx={{ width: "100%", marginBottom: "10px" }}>


                    <Grid item xs={12}>
                      <Grid item xs={5}>
                        {/* Autocomplement sessions  */}

                        <CacheProvider value={cacheRtl}>


                          <Autocomplete
                            noOptionsText={'حدث الصفحة مرة أخرى'}
                            classes={classes_listdfep}
                            id="combo-box-demowly"
                            onChange={onwlyChanged_session}
                            value={selectedOption_session} // Use the value prop to set the selected option
                            options={options_session}

                            defaultValue={options_session[0]} // Set the defaultValue to the first option
                            sx={{ mt: 1, mb: 1 }}
                            renderOption={(props, option) => {
                              const { label, indexvalue } = option;
                              return (
                                <span
                                  {...props}
                                  style={{
                                    backgroundColor: indexvalue % 2 === 0 ? "#add8e6" : "#FFFFFF",
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  }}
                                >
                                  {indexvalue === -1 ? '- ' + label : label}
                                </span>
                              );
                            }}

                            renderInput={(params) => (
                              <Box display="flex" justifyContent="center">
                                <TextField fullWidth {...params} label="الدورات" />
                              </Box>
                            )}


                          />




                        </CacheProvider>

                      </Grid>
                    </Grid>



                    <Grid item xs={12}>
                      <Grid item xs={5}>
                        {/* Autocomplement Wilayat  */}

                        <CacheProvider value={cacheRtl}>


                          <Autocomplete
                            noOptionsText={'حدث الصفحة مرة أخرى'}
                            classes={classes_listdfep}
                            id="combo-box-demowly"
                            onChange={onwlyChanged}
                            value={selectedOption} // Use the value prop to set the selected option
                            options={options_Wlylist}

                            defaultValue={options_Wlylist[0]} // Set the defaultValue to the first option
                            sx={{ mt: 1, mb: 1 }}
                            renderOption={(props, option) => {
                              const { label, indexvalue } = option;
                              return (
                                <span
                                  {...props}
                                  style={{
                                    backgroundColor: indexvalue % 2 === 0 ? "#add8e6" : "#FFFFFF",
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  }}
                                >
                                  {indexvalue === -1 ? '- ' + label : label}
                                </span>
                              );
                            }}

                            renderInput={(params) => (
                              <Box display="flex" justifyContent="center">
                                <TextField fullWidth {...params} label="المديريات" />
                              </Box>
                            )}


                          />




                        </CacheProvider>

                      </Grid>
                    </Grid>

                    {uniqueNomEtValues.length > 0 ? (

                      <Grid item xs={7}>





                        <Autocomplete
                          noOptionsText={'   '}

                          options={uniqueNomEtValues}
                          onChange={handleNomEtChange}
                          value={selectedNomEtValue}
                          defaultValue={uniqueNomEtValues[0]} // Set the defaultValue to the first option

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="المؤسسات"
                              fullWidth
                              InputLabelProps={{
                                style: {
                                  color: "#191970",
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 18,
                                  fontWeight: 700,
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: {
                                  color: "#191970",
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 18,
                                  fontWeight: 700,
                                },
                              }}
                            />
                          )}
                          renderOption={(props, option) => {
                            const { label, key } = option;
                            return (
                              <span
                                {...props}
                                style={{
                                  backgroundColor: key % 2 === 0 ? "#add8e6" : "#FFFFFF",
                                  color: "#191970",
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 18,
                                  fontWeight: 700,
                                }}
                              >
                                {key === -1 ? label : label}

                              </span>
                            );
                          }}
                        />



                      </Grid>

                    ) : null}

                  </Grid>


                  {loadingdata_mfep ? <>

                    <Box sx={{ width: '100%' }}>
                      <LinearProgress color="success" />
                    </Box>

                  </> : null}
                  {/* 
<pre style={{ fontSize: 10 }}>
        {JSON.stringify(selectedRows, null, 4)}
      </pre>
*/}
                  {/* 
 <DataGrid
      checkboxSelection
      onRowSelectionModelChange={(ids) => {
        const selectedIDs = new Set(ids);
        const selectedRows = filteredRows.filter((row) =>
          selectedIDs.has(row.id),
        );

         ///alert(JSON.stringify(selectedRows, null, 4)); // Alert selected rows

         setSelectedRows(selectedRows);
      }}
*/}
                  <DataGrid

                    locale="ar-SD"

                    rows={filteredRows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    getRowId={(row) => row.id}
                    getRowClassName={(params) =>
                      parseInt(params.row.id_rows, 10) % 2 === 0 ? 'even-row' : 'odd-row'
                    }

                    slots={{
                      toolbar: CustomToolbar,
                    }}

                    slotProps={{
                      toolbar: { setRows, setRowModesModel, columns, handleExportExcel, validation_multiple, selectedRows },
                    }}

                    sx={{
                      boxShadow: 2,
                      border: 2,
                      borderColor: 'primary.light',




                      '& .MuiDataGrid-cell': {
                        fontFamily: 'Amiri',
                        fontSize: '20px',
                        textAlign: 'center',
                        backgroundColor: 'rgba(255,255,255,0.65)',
                        borderRight: '1px solid #ccc', // Add this line to apply right border to the cell


                      },
                      '& .MuiDataGrid-row:hover': {
                        backgroundColor: '#2b84dd', // Background color when row is hovered
                      },

                      '& .even-row': {
                        backgroundColor: '#2b84dd61', // Background color for even rows
                      },
                      '& .odd-row': {
                        backgroundColor: '#ffffff', // Background color for odd rows
                      },


                    }}

                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 25, page: 0 },
                      },
                    }}
                  />



                </Grid>



              </ThemeProvider>
            </CacheProvider>

          </Box>

          <Footer />

        </div>
        <ConfirmationDialog open={isConfirmationOpen} onClose={handleConfirmationResponse} />
        <SaveConfirmationDialog open={isSaveConfirmationOpen} onClose={handleSaveConfirmationResponse} />
      </Box>

    </>
  );
}

export default OffersMfep;
