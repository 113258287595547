import React, { useState, useEffect, useRef } from "react";

import { Typography, Box } from "@mui/material";

import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Select from "@mui/material/Select";
import { APP_PREFIX } from "./config"; // Adjust the path if necessary
import Autocomplete from "@mui/material/Autocomplete";
import LinearProgress from "@mui/material/LinearProgress";
import useAuthentication_etab from "./useAuthentication_etab";

import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  arSD,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import ReplayIcon from "@mui/icons-material/Replay";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { CacheProvider } from "@emotion/react";
import MenuItem from "@mui/material/MenuItem";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import XLSX from "sheetjs-style";
import Footer from "./Footer";
import Header from "./Header";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "./config"; // Adjust the path if necessary
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { createTheme, ThemeProvider, Theme } from "@mui/material/styles";
import createCache from "@emotion/cache";

import "../containers/amirifont.css"; // Import the font CSS file
import Divider from "@mui/material/Divider";
import LockIcon from "@mui/icons-material/Lock";
import { makeStyles } from "@material-ui/core/styles";

const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme(
  {
    direction: "rtl",
    overrides: {
      MuiOutlinedInput: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: hoverColor,
          },
          "&$focused $notchedOutline": {
            borderColor: focusColor,
          },
        },
        notchedOutline: {
          borderColor: defaultColor,
        },
      },
    },
    fieldset: {
      fontFamily: '"Changa", sans-serif',
      fontSize: 45,
    },
  },
  arSD
);

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const StyledBox = styled("div")(({ theme }) => ({
  height: 400,
  width: "100%",
  "& .MuiDataGrid-cell--editable": {
    backgroundColor:
      theme.palette.mode === "dark" ? "#376331" : "rgb(217 243 190)",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${
      theme.palette.mode === "dark" ? 0 : 0.1
    })`,
    color: theme.palette.mode === "dark" ? "#ff4343" : "#750f0f",
  },
}));

let rowsdata = [];
let promiseTimeout;
function validateName(username) {
  const alphabetRegex = /^[a-zA-Z\s]+$/;
  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      const isValid = alphabetRegex.test(username);
      resolve(
        isValid ? null : "Username must contain only alphabets (a to z)."
      );
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

let promiseTimeoutar;
function validateNamear(username) {
  const arabicAlphabetRegex = /^[\u0600-\u06FF\s]+$/;

  return new Promise((resolve) => {
    promiseTimeoutar = setTimeout(() => {
      const isValid = arabicAlphabetRegex.test(username);
      resolve(isValid ? null : "Username must contain only Arabic alphabets.");
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEditName(params) {
  return <NameEditInputCell {...params} />;
}

const apiUrl = `${API_BASE_URL}/api/getcandbyIDDFEP`;

function CustomToolbar({
  setRows,
  setRowModesModel,
  handleExportExcel,
  columns,
}) {
  return (
    <GridToolbarContainer style={{ fontFamily: "Amiri", fontSize: "20px" }}>
      <GridToolbarColumnsButton
        style={{ fontFamily: "Amiri", fontSize: "20px" }}
      />
      <GridToolbarFilterButton
        style={{ fontFamily: "Amiri", fontSize: "20px" }}
      />
      <GridToolbarDensitySelector
        style={{ fontFamily: "Amiri", fontSize: "20px" }}
      />
      <Button
        style={{ fontFamily: "Amiri", fontSize: "20px" }}
        onClick={handleExportExcel}
        color="primary"
      >
        تنزيل كملف إكسال
      </Button>
      <GridToolbarExport style={{ fontFamily: "Amiri", fontSize: "20px" }} />

      <GridToolbarQuickFilter
        style={{ fontFamily: "Amiri", fontSize: "20px", marginRight: "25px" }}
        inputProps={{ style: { fontFamily: "Amiri", fontSize: "20px" } }}
      />
    </GridToolbarContainer>
  );
}

let options_Wly = [];
let Wlylists = [];

function ShowAllAprsDfep() {
  const {
    IDetablissement,
    IDDFEP,
    IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep,
  } = useAuthentication_etab();

  const [rows, setRows] = React.useState([]);
   const [rowModesModel, setRowModesModel] = React.useState({});
  const [isAdding, setIsAdding] = React.useState(false);
  const [deletedRowId, setDeletedRowId] = useState(null);
  const [idforsave, setidforsave] = useState(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
  const [editedValue, setEditedValue] = useState("");

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const [valuegenpw, setvaluegenpw] = useState("");
  const [deleted_NomAr, setDeleted_NomAr] = useState(null);
  const [deleted_PrenomAr, setDeleted_PrenomAr] = useState(null);

  const [filteredRows, setFilteredRows] = useState([]);
  const [gridKey, setGridKey] = useState(0); // Add a key for the DataGrid

  const [uniqueNomEtValues, setUniqueNomEtValues] = useState([]);
  const [uniquesession_nomValues, setUniquesession_nomValues] = useState([]);

  const [Selectedsession_nomValue, setSelectedsession_nomValue] = useState({
    indexvalue: 0,
    value: 999,
    label: "جميع الدورات",
  });

  const [uniquespecialiteValues, setuniquespecialiteValues] = useState([]);

  const [uniqueNomFormationValues, setuniqueNomFormationValues] = useState([]);
  const [uniquesemValues, setuniquesemValues] = useState([]);

  const [selectedNomFormationValue, setselectedNomFormationValue] = useState({
    indexvalue: 0,
    value: 999,
    label: "جميع الأنماط",
  });
  const [selectedsemValue, setselectedsemValue] = useState({
    indexvalue: 0,
    value: 999,
    label: "جميع السداسيات",
  });

  const [selectedNomEtValue, setSelectedNomEtValue] = useState({
    indexvalue: 0,
    value: 999,
    label: "كل المؤسسات",
  });
  const [selectedspecialiteValue, setselectedspecialiteValue] = useState({
    indexvalue: 0,
    value: 999,
    label: "كل التخصصات",
  });

  const [IDDFEP_selected, setIDDFEP_selected] = useState(1);

  const [loadingdata_mfep, setloadingdata_mfep] = useState(false);

  const editedValueRef = useRef(editedValue);

  const [selectedOption, setSelectedOption] = useState(null);

  const onwlyChanged = (event, values) => {
    if (values) {
      setSelectedOption(values);
      setIDDFEP_selected(values.value);
    } else {
      return;
    }
  };

  // Update the ref whenever editedValue changes
  useEffect(() => {
    editedValueRef.current = editedValue;
  }, [editedValue]);

  const navigate = useNavigate();

  const useStyles_listdfep = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
        fontFamily: '"Tajawal", sans-serif',
        fontSize: 18,
        direction: "rtl",
      },
    },
    inputRoot: {
      color: "#191970",

      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        fontFamily: '"Tajawal", sans-serif',
        fontWeight: 700,
        color: "#191970",
        // Default left padding is 6px
        paddingLeft: 26,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#add8e6",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "red",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "purple",
      },
    },
  }));

  const classes_listdfep = useStyles_listdfep();


 ;

  useEffect(() => {
    if (filteredRows && filteredRows.length > 0) {
      // Increment the key to force a remount of the DataGrid
      setGridKey((prevKey) => {
        ////alert(`Previous key: ${prevKey}`);
        return prevKey + 1;
      });
    }
  }, [filteredRows]);

  useEffect(() => {
    if (IDDFEP == null || Number(IDDFEP) <= 0) {
      return; // Or any other handling you might want
    }

    setloadingdata_mfep(true);
    setRows([]); // Clear rows before setting new rows
    setFilteredRows([]);

    fetch(apiUrl + "/" + IDDFEP, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const apiRows = data.data.map((item, index) => ({
          id: index + 1,
          id_rows: index + 1,
          code_etab: item.code_etab,
          MatSt: item.NumIns,
          NIN: item.Nin,
          ID: index + 1,
          IDapprenant: item.IDapprenant,
          IDCandidat: item.IDCandidat,
          NomAr: item.Nom_Ca,
          NomFr: item.NomFr,
          PrenomAr: item.Prenom,
          PrenomFr: item.PrenomFr,
          NomSection: item.Nom, ///Section.Nom
          DateNaisLieuNais: item.DateNais + " " + item.LieuNais,
          crypted_id: item.crypted_id,
          Nom_Et: item.Nom_Et,
          Password: "****", ///item.Password_can,
          NumSem: item.NumSem,
          MoyAvr: isNaN(parseFloat(item.MoyAvr)) ? '0.00' : parseFloat(item.MoyAvr).toFixed(2), 
          MoyApr: isNaN(parseFloat(item.MoyApr)) ? '0.00' : parseFloat(item.MoyApr).toFixed(2), 
          NoteStage: isNaN(parseFloat(item.NoteStage)) ? '0.00' : parseFloat(item.NoteStage).toFixed(2), 
          NoteSoutenance: isNaN(parseFloat(item.NoteSoutenance)) ? '0.00' : parseFloat(item.NoteSoutenance).toFixed(2), 
          NoteMemoire: isNaN(parseFloat(item.NoteMemoire)) ? '0.00' : parseFloat(item.NoteMemoire).toFixed(2), 
          Mode_formation_Nom: item.Mode_formation_Nom,
          Mode_formation_Abr: item.Mode_formation_Abr,
          IDMode_formation: item.IDMode_formation,
          CodeSpec: item.CodeSpec,
          Nom_specialite: item.Nom_specialite,
          NomFr_specialite: item.NomFr_specialite,
          IDSpecialite: item.IDSpecialite,
          IDSession: item.IDSession,
          session_Nom: item.session_Nom,
          session_DateD: item.session_DateD,

          actions: "",
        }));
        setRows(apiRows);
        ///rowsdata=apiRows;
        setFilteredRows(apiRows);
        // Extract unique 'Nom_Et' values
        /// const uniqueValues = [...new Set(apiRows.map(row => row.code_etab))];

        //// session
        const uniquesession_nom = [
          ...new Set(apiRows.map((row) => row.IDSession)),
        ];

        const uniqueValues_session = uniquesession_nom.map(
          (IDSession, index) => {
            const rowsWithIDSession = apiRows.filter(
              (row) => row.IDSession === IDSession
            );
            const sessionWithEarliestDate = rowsWithIDSession.reduce(
              (earliest, current) => {
                return earliest.session_DateD < current.session_DateD
                  ? earliest
                  : current;
              }
            );
            return {
              indexvalue: index,
              value: IDSession,
              label: sessionWithEarliestDate.session_Nom,
              dateD: sessionWithEarliestDate.session_DateD,
            };
          }
        );

        // Sort uniqueValues_session by session_DateD
        uniqueValues_session.sort((a, b) => {
          return b.dateD.localeCompare(a.dateD);
        });

      

        // Adding the desired object at the beginning of uniqueValues_session
        uniqueValues_session.unshift({
          indexvalue: 0,
          value: 999,
          label: "جميع الدورات",
        });

          // Reset indexvalue based on the sorted order
          uniqueValues_session.forEach((item, index) => {
            item.indexvalue = index;
          });

        setUniquesession_nomValues([]);
        setUniquesession_nomValues(uniqueValues_session);

        setSelectedsession_nomValue({
          indexvalue: 0,
          value: 999,
          label: "جميع الدورات",
        });

        //////////// etablissement

        const uniqueCodeEtabs = [
          ...new Set(apiRows.map((row) => row.code_etab)),
        ];
        const uniqueValues = uniqueCodeEtabs.map((code_etab, index) => ({
          indexvalue: index,
          value: code_etab,
          label:
            code_etab +
            " | " +
            apiRows.find((row) => row.code_etab === code_etab).Nom_Et,
        }));

        // Adding the desired object at the beginning of uniqueValues
        uniqueValues.unshift({
          indexvalue: 0,
          value: 999,
          label: "كل المؤسسات",
        });

         // Reset indexvalue based on the sorted order
         uniqueValues.forEach((item, index) => {
          item.indexvalue = index;
        });

        setUniqueNomEtValues([]);
        setUniqueNomEtValues(uniqueValues);

        setSelectedNomEtValue({
          indexvalue: 0,
          value: 999,
          label: "كل المؤسسات",
        });

        /////////Nom formation

        const uniqueNomformationabs = [
          ...new Set(apiRows.map((row) => row.IDMode_formation)),
        ];
        const uniqueValuesNomFormation = uniqueNomformationabs.map(
          (IDMode_formation, index) => ({
            indexvalue: index,
            value: IDMode_formation,
            label: apiRows.find(
              (row) => row.IDMode_formation === IDMode_formation
            ).Mode_formation_Abr,
          })
        );

        // Adding the desired object at the beginning of uniqueValuesNomFormation
        uniqueValuesNomFormation.unshift({
          indexvalue: 0,
          value: 999,
          label: "جميع الأنماط",
        });
          // Reset indexvalue based on the sorted order
          uniqueValuesNomFormation.forEach((item, index) => {
            item.indexvalue = index;
          });
        setuniqueNomFormationValues([]);
        setuniqueNomFormationValues(uniqueValuesNomFormation);

        setselectedNomFormationValue({
          indexvalue: 0,
          value: 999,
          label: "جميع الأنماط",
        });

        ///////////// semestre

        const semData = [
          { indexvalue: 0, value: 999, label: "جميع السداسيات" },
          { indexvalue: 1, value: 1, label: "السداسي الأول" },
          { indexvalue: 2, value: 2, label: "السداسي الثاني" },
          { indexvalue: 3, value: 3, label: "السداسي الثالث" },
          { indexvalue: 4, value: 4, label: "السداسي الرابع" },
          { indexvalue: 5, value: 5, label: "السداسي الخامس" },
        ];

        const options_sem = semData.map((semest, i) => ({
          value: semest.value,
          label: semest.label,
          indexvalue: semest.indexvalue,
        }));

        setuniquesemValues(options_sem);

        setselectedsemValue({
          indexvalue: 0,
          value: 999,
          label: "جميع السداسيات",
        });

          

        /////////Nom specialite

        const uniquespecialiteabs = [
          ...new Set(apiRows.map((row) => row.IDSpecialite)),
        ];
        const uniqueValuesspecialite = uniquespecialiteabs.map(
          (IDSpecialite, index) => ({
            indexvalue: index,
            value: IDSpecialite,
            label:
              apiRows.find((row) => row.IDSpecialite === IDSpecialite)
                .CodeSpec +
              " | " +
              apiRows.find((row) => row.IDSpecialite === IDSpecialite)
                .Nom_specialite,
          })
        );

        // Adding the desired object at the beginning of uniqueValuesspecialite
        uniqueValuesspecialite.unshift({
          indexvalue: 0,
          value: 999,
          label: "كل التخصصات",
        });
         // Reset indexvalue based on the sorted order
         uniqueValuesspecialite.forEach((item, index) => {
          item.indexvalue = index;
        });
        setuniquespecialiteValues([]);
        setuniquespecialiteValues(uniqueValuesspecialite);

        setselectedspecialiteValue({
          indexvalue: 0,
          value: 999,
          label: "كل التخصصات",
        });

        setloadingdata_mfep(false);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [IDDFEP]);

  /*
  useEffect(() => {
    if(selectedspecialiteValue==null && uniquespecialiteValues.length>0 ){
      alert("uniquespecialiteValues.length");
    setselectedspecialiteValue({
      indexvalue: 0,
      value: 999,
      label: "كل التخصصات",
    });
    }

    
  }, [uniqueNomEtValues,uniqueNomFormationValues,uniquesemValues,uniquespecialiteValues]);
  */

  useEffect(() => {
    if (
      selectedNomFormationValue &&
      selectedNomEtValue &&
      selectedsemValue &&
      selectedspecialiteValue &&
      Selectedsession_nomValue
    ) {
      // Reset filteredRows
      setFilteredRows([]);

      // Check for default values
      const isNomFormationDefault =
        Number(selectedNomFormationValue.value) === 999;
      const isNomEtDefault = Number(selectedNomEtValue.value) === 999;
      const isSemDefault = Number(selectedsemValue.value) === 999;
      const isSpecDefault = Number(selectedspecialiteValue.value) === 999;
      const isSessionDefault = Number(Selectedsession_nomValue.value) === 999;

      // Filter rows based on selection
      const filteredData = rows.filter((row) => {
        if (
          isNomFormationDefault &&
          isNomEtDefault &&
          isSemDefault &&
          isSpecDefault &&
          isSessionDefault
        ) {
          // No filtering needed, set filteredRows to all rows
          return true;
        } else {
          // Filter rows based on selected values
          return (
            (isNomFormationDefault ||
              Number(row.IDMode_formation) === Number(selectedNomFormationValue.value)) &&
            (isNomEtDefault || row.code_etab === selectedNomEtValue.value) &&
            (isSemDefault || Number(row.NumSem) === Number(selectedsemValue.value)) &&
            (isSpecDefault ||
              Number(row.IDSpecialite) === Number(selectedspecialiteValue.value)) &&
            (isSessionDefault ||
              Number(row.IDSession) === Number(Selectedsession_nomValue.value))
          );
        }
      });

      setFilteredRows(filteredData);
    }
  }, [
    selectedNomEtValue,
    selectedNomFormationValue,
    selectedsemValue,
    selectedspecialiteValue,
    Selectedsession_nomValue,
  ]);

  const handlesessionChange = (event, newValue) => {
    if (newValue) {
      // Handle the change according to newValue
      setSelectedsession_nomValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state

      // You can perform further actions based on the selected value if needed
      console.log("Selected session Value:", newValue);
    }
  };

  const handlespecialiteChange = (event, newValue) => {
    if (newValue) {
      // Handle the change according to newValue
      setselectedspecialiteValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state

      // You can perform further actions based on the selected value if needed
      console.log("Selected specialite Value:", newValue);
    }
  };

  const handleNomEtChange = (event, newValue) => {
    if (newValue) {
      // Handle the change according to newValue
      setSelectedNomEtValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state

      // You can perform further actions based on the selected value if needed
      console.log("Selected Nom Et Value:", newValue);
    }
  };

  const handleNomFormationChange = (event, newValue) => {
    if (newValue) {
      // Handle the change according to newValue
      setselectedNomFormationValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state

      // You can perform further actions based on the selected value if needed
      console.log("Selected Nom formation Value:", newValue);
    }
  };

  const handlesemChange = (event, newValue) => {
    if (newValue) {
      // Handle the change according to newValue
      setselectedsemValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state

      // You can perform further actions based on the selected value if needed
      console.log("Selected semestre Value:", newValue);
    }
  };

  /*
  const handleNomEtChange = event => {
    ///alert(event.target.value)
    
    setSelectedNomEtValue(event.target.value);
  };*/

  const handleRowEditStop = (params, event) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridRowEditStopReasons.commit
    ) {
      event.defaultMuiPrevented = true;
      const editedRow = rows.find((row) => row.id === params.id);

      if (editedRow) {
        /// alert(`Editing stopped for ID: ${editedRow.id}`);
        // Trigger the save operation for the edited row
      }
    }
  };

  const handlecert_inscription = (id) => () => {
    const rowToid = rows.find((row) => row.id === id);
    if (rowToid) {
      // Extract NomAr value from the row
      const { crypted_id } = rowToid;

      // Retrieve the token from sessionStorage
      const tokenEtab = sessionStorage.getItem("authData_73xJ#E");

      // Store the token in localStorage
      localStorage.setItem("authData_73xJ#E", tokenEtab);

      /// navigate(`/ETATCertificatInscriptionPDF/${crypted_id}`);

      if (tokenEtab) {
        // Extract NomAr value from the row
        const { crypted_id } = rowToid;

        // Open link in a new tab
        window.open(`/ETATCertificatInscriptionPDF/${crypted_id}`, "_blank");
      } else {
        // Handle case where tokenEtab is not available
        console.error("TokenEtab not found in sessionStorage.");
      }
    }
  };

  const handlecert_certscolaire = (id) => () => {
    const rowToid = rows.find((row) => row.id === id);
    if (rowToid) {
      // Extract NomAr value from the row
      const { crypted_id } = rowToid;

      // Retrieve the token from sessionStorage
      const tokenEtab = sessionStorage.getItem("authData_73xJ#E");

      // Store the token in localStorage
      localStorage.setItem("authData_73xJ#E", tokenEtab);

      /// navigate(`/ETATCertificatScolaritePDF/${crypted_id}`);

      if (tokenEtab) {
        // Extract NomAr value from the row
        const { crypted_id } = rowToid;

        // Open link in a new tab
        window.open(`/ETATCertificatScolaritePDF/${crypted_id}`, "_blank");
      } else {
        // Handle case where tokenEtab is not available
        console.error("TokenEtab not found in sessionStorage.");
      }
    }
  };

  const handleEditClick = (id) => () => {
    const rowToEdit = rows.find((row) => row.id === id);
    const editedValues = {};

    // Copy all fields from the row to the editedValues object
    Object.keys(rowToEdit).forEach((field) => {
      editedValues[field] = rowToEdit[field];
    });

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setEditedValue({ ...editedValue, [id]: editedValues });
  };

  const [idsaved, setidsaved] = useState(0);

  const [confirmationTrigger, setConfirmationTrigger] = useState(false);

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    //setidforsave(id);
    ///setConfirmationTrigger(true); // Set the trigger for confirmation
  };

  useEffect(() => {
    if (confirmationTrigger) {
      setSaveConfirmationOpen(true);
      setConfirmationTrigger(false); // Reset the trigger after executing
    }
  }, [confirmationTrigger, rowModesModel]);

  const handleDeleteClick = (id) => () => {
    const rowToDelete = rows.find((row) => row.id === id);
    if (rowToDelete) {
      // Extract NomAr value from the row
      const { ID } = rowToDelete;
      const { NomAr } = rowToDelete;
      const { PrenomAr } = rowToDelete;
      // Open the confirmation dialog
      setConfirmationOpen(true);
      setDeletedRowId(ID);
      setDeleted_NomAr(NomAr);
      setDeleted_PrenomAr(PrenomAr);
    }
  };

  const handleSaveConfirmationResponse = async (confirmed) => {
    // Close the confirmation dialog
    setSaveConfirmationOpen(false);

    if (confirmed) {
      const idforsavea = idforsave;

      setidforsave(null);
    } else {
      // User clicked "Cancel," do nothing
    }
  };

  const SaveConfirmationDialog = ({ open, onClose }) => {
    return (
      <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>Confirm Save</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save this row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const [addRecordClicked, setAddRecordClicked] = useState(false);

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  /*

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  
    // Check if a row is in edit mode
    const editedRowId = Object.keys(newRowModesModel).find(
      (id) => newRowModesModel[id]?.mode === GridRowModes.Edit
    );
  
    // If a row is in edit mode, trigger the save operation
    if (editedRowId) {
      handleSaveOperation(editedRowId);
    }
  };

*/
  const preProcessEditCellPropsAr = async (params) => {
    const errorMessage = await validateNamear(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const preProcessEditCellProps = async (params) => {
    const errorMessage = await validateName(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const useStyles = makeStyles({
    cancelBtn: {
      backgroundColor: "#4caf50", // أخضر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#388e3c",
      },
    },
    yesBtn: {
      backgroundColor: "#f44336", // أحمر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#d32f2f",
      },
    },
  });

  const ConfirmationDialog = ({ open, onClose }) => {
    const classes = useStyles();

    return (
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={themetextfieldnew}>
          <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle>
              <Divider>
                <Grid item sx={{ mt: 2 }}>
                  <Typography
                    style={{
                      color: "red",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    تأكيد الحذف <DeleteIcon sx={{ ml: "0px" }} />
                  </Typography>
                </Grid>
              </Divider>
            </DialogTitle>

            <DialogContent>
              <DialogContentText
                sx={{
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 900,
                }}
              >
                هل أنت متأكد من أنك تريد حذف{" "}
                <span style={{ color: "black" }}>
                  {deleted_PrenomAr} {deleted_NomAr}؟
                </span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onClose(false)}
                className={classes.cancelBtn}
                color="primary"
                sx={{
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 900,
                  backgroundColor: "#4caf50", // أخضر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#388e3c",
                  },
                }}
              >
                إلغاء
              </Button>
              <Button
                onClick={() => onClose(true)}
                className={classes.yesBtn}
                color="primary"
                autoFocus
                sx={{
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 900,
                  backgroundColor: "#f44336", // أحمر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
              >
                نعم
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </CacheProvider>
    );
  };

  const handleExportExcel = () => {
    // Extracting only the 'name' field and 'headerName' from columns
    const relevantColumns = columns.map((col) => ({
      name: col.field,
      headerName: col.headerName,
    }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      rows.map((row) => {
        const newRow = {};
        relevantColumns.forEach((col) => {
          newRow[col.headerName] = row[col.name];
        });
        return newRow;
      })
    );

    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: "Calibri",
        sz: 11,
        bold: true,
        color: {
          rgb: "FFFFAA00",
        },
      },
    };

    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
    const timestamp = Math.floor(Date.now() / 1000);

    XLSX.writeFile(wb, `Moutakawin_${timestamp}.xlsx`);
  };

  const columns = [
    {
      field: "id_rows",
      headerName: "ترقيم",
      minWidth: 70,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    },

    {
      field: "session_Nom",
      headerName: "الدورة",
      minWidth: 170,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    },

    {
      field: "code_etab",
      headerName: "رمز المؤسسة",
      minWidth: 130,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    },

    {
      field: "Nom_Et",
      headerName: "المؤسسة",
      flex: 1,
      minWidth: 130,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    },

    {
      field: "MatSt",
      minWidth: 200,
      headerName: "رقم التسجيل",
      headerClassName: "super-app-theme--header",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    /* { field: 'NIN',flex: 1, headerName: 'رقم التعريف الوطني NIN', headerClassName: 'super-app-theme--header',
     
     headerAlign: 'center', },*/
    {
      field: "NomAr",
      headerName: "اللقب",
      editable: false,
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerAlign: "center",
      align: "center",
      preProcessEditCellProps: preProcessEditCellPropsAr,
      renderEditCell: renderEditName,
      minWidth: 170,
    },

    {
      field: "NomFr",
      headerName: "Nom",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      editable: false,
      preProcessEditCellProps: preProcessEditCellProps,
      renderEditCell: renderEditName,
      minWidth: 170,
    },

    {
      field: "PrenomAr",
      flex: 1,
      headerName: "الإسم",
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      preProcessEditCellProps: preProcessEditCellPropsAr,
      renderEditCell: renderEditName,
      minWidth: 170,
    },
    {
      field: "PrenomFr",
      headerName: "Prénom",
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      flex: 1,
      align: "center",
      preProcessEditCellProps: preProcessEditCellProps,
      renderEditCell: renderEditName,
      minWidth: 170,
    },

    {
      field: "DateNaisLieuNais",
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerName: "تاريخ الميلاد و مكانه",
      editable: false,
      minWidth: 170,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "Mode_formation_Abr",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerName: "نمط التكوين",
      editable: false,
      minWidth: 170,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Nom_specialite",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerName: "التخصص",
      editable: false,
      minWidth: 170,
      align: "center",
      headerAlign: "center",
    },

    /*
        NumSem:item.NumSem,
       MoyAvr:item.MoyAvr,
       MoyApr:item.MoyApr,
       NoteStage:item.NoteStage,
       NoteSoutenance:item.NoteSoutenance,
       NoteMemoire:item.NoteMemoire,
       */
    {
      field: "NumSem",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerName: "السداسي",
      editable: false,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "MoyAvr",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerName: "معدل د. عادية",
      editable: false,
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "MoyApr",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerName: "معدل بعد الإستدراك",
      editable: false,
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "NoteStage",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerName: "نقطة التربص",
      editable: false,
      minWidth: 140,
      align: "center",
      headerAlign: "center",
      hideable: true, // Allow user interaction
    },
    {
      field: "NoteSoutenance",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerName: "نقطة المناقشة",
      editable: false,
      minWidth: 140,
      align: "center",
      headerAlign: "center",
      hideable: true, // Allow user interaction
    },
    {
      field: "NoteMemoire",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerName: "نقطة المذكرة",
      editable: false,
      minWidth: 140,
      align: "center",
      headerAlign: "center",
      // Initially hidden but user can show it
      hideable: true, // Allow user interaction
    },

    ///{ field: 'crypted_id', headerName: 'crypted_id', width: 200, editable: false },
    {
      field: "actions",
      type: "actions",
      headerName: "شهادات",
      flex: 1,
      align: "left",
      minWidth: 220,
      cellClassName: "actions",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      getActions: ({ id,row }) => {
        const isfirstsem = row && Number(row.NumSem) === 1;
        return [
          <Button
            onClick={handlecert_inscription(id)}
            variant="contained"
            endIcon={
              <PictureAsPdfIcon
                sx={{ mr: "2px", ml: "-2px", transform: "rotate(0deg)" }}
              />
            }
            style={{ backgroundColor: "#1976d2", width: "fit-content", display: !isfirstsem?'none':null }}
            disabled={!isfirstsem}
           

          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 12,
                fontWeight: 900,
              }}
            >
              تسجيل
            </Typography>
          </Button>,

          <Button
            onClick={handlecert_certscolaire(id)}
            variant="contained"
            endIcon={
              <PictureAsPdfIcon
                sx={{ mr: "2px", ml: "-2px", transform: "rotate(0deg)" }}
              />
            }
            style={{ backgroundColor: "rgb(12 132 42)", width: "fit-content" }}
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 12,
                fontWeight: 900,
              }}
            >
              تربص
            </Typography>
          </Button>,
        ];
      },
    },
  ];

  function generateRandomString(length = 6) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const CopyToClipboardButton = (value) => {
    // Create a temporary input element
    const tempInput = document.createElement("input");
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand("copy");

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    setSnackbar({ children: `  تم نسخ  ${value}  `, severity: "success" });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };

  useEffect(() => {
    if (!loadingauth) {
      if (
        IDetablissement === null ||
        IDDFEP === null ||
        IDNature_etsF === null ||
        Number(IDNature_etsF) !== 5
      ) {
        // Navigate to the 404 page if any value is missing
        navigate("/404");
        return;
      }
    }
  }, [
    IDetablissement,
    IDDFEP,
    IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    navigate,

    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep,
  ]); // Add navigate to dependencies

  if (loadingauth) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress color="success" />
      </Box>
    );
  }

  return (
    <>
      <Header
        IDetablissement={IDetablissement}
        IDDFEP={IDDFEP}
        IDNature_etsF={IDNature_etsF}
        IDCandidat={IDCandidat}
        NumSem={NumSem}
        IDapis_userslist={IDapis_userslist}
        loadingauth={loadingauth}
        Singup_active={Singup_active}
        download_cert_scol_active={download_cert_scol_active}
        EditingInfo_active={EditingInfo_active}
        Eval_notes_active={Eval_notes_active}
        download_cert_inscription_active={download_cert_inscription_active}
        Singup_active_Dfep={Singup_active_Dfep}
        download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
        EditingInfo_active_Dfep={EditingInfo_active_Dfep}
        Eval_notes_active_Dfep={Eval_notes_active_Dfep}
        download_cert_inscription_active_Dfep={
          download_cert_inscription_active_Dfep
        }
        Singup_active_Mfep={Singup_active_Mfep}
        download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
        EditingInfo_active_Mfep={EditingInfo_active_Mfep}
        Eval_notes_active_Mfep={Eval_notes_active_Mfep}
        download_cert_inscription_active_Mfep={
          download_cert_inscription_active_Mfep
        }
      />

      <Box
        sx={{
          height: 500,
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <div style={{ height: 400, width: "100%" }}>
          <Box
            sx={{
              "& .super-app-theme--header": {
                fontFamily: "Amiri",
                fontSize: "20px",
                fontWeight: "bold", // Make the font bold
                backgroundColor: "#B9D3EE",
                borderRight: "1px solid #ccc", // Add this line to apply right border to the cell
              },
            }}
          >
            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={themetextfieldnew}>
                {!!snackbar && (
                  <Snackbar
                    open
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                    sx={{
                      marginTop: 12,
                    }}
                  >
                    <Alert
                      {...snackbar}
                      onClose={handleCloseSnackbar}
                      sx={{
                        display: "flex",
                        fontFamily: "Amiri",
                        fontSize: "20px",
                        "& .MuiAlert-icon": { marginLeft: "10px" },
                        "& .MuiAlert-action": { marginRight: "8px" },
                      }} // Adjust padding here
                    />
                  </Snackbar>
                )}

                <Grid container>
                  <Grid item sx={{ width: "100%" }}>
                    <Divider sx={{ mt: "20px" }}>
                      <Grid item>
                        <Typography
                          style={{
                            color: "#191970",
                            fontFamily: '"Tajawal", sans-serif',
                            fontSize: 25,
                            fontWeight: 700,
                            marginBottom: "25px",
                          }}
                          variant="h3"
                          gutterBottom
                        >
                          <PictureAsPdfIcon sx={{ mr: "5px" }} />
                          مطبوعات المتكون
                        </Typography>
                      </Grid>
                    </Divider>
                  </Grid>

                  <Grid
                    container
                    alignItems="center"
                    align="center"
                    justifyContent="center"
                    sx={{ width: "100%", marginBottom: "10px" }}
                  >
                     

                    <Grid container>
                      {uniquesession_nomValues.length > 0 ? (
                        <Grid item xs={2} sx={{ pr: 1 }}>
                          <Autocomplete
                            noOptionsText={"حدد الدورة"}
                            options={uniquesession_nomValues}
                            onChange={handlesessionChange}
                            value={Selectedsession_nomValue}
                            defaultValue={uniquesession_nomValues[0]} // Set the defaultValue to the first option
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="الدورات"
                                fullWidth
                                InputLabelProps={{
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                              />
                            )}
                            renderOption={(props, option) => {
                              const { label, indexvalue } = option;
                              return (
                                <span
                                  {...props}
                                  style={{
                                    backgroundColor:
                                      indexvalue % 2 === 0
                                        ? "#add8e6"
                                        : "#FFFFFF",
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  }}
                                >
                                  {indexvalue === -1 ? label : label}
                                </span>
                              );
                            }}
                          />
                        </Grid>
                      ) : null}

                      {uniqueNomEtValues.length > 0 ? (
                        <Grid item xs={3} sx={{ pr: 1 }}>
                          <Autocomplete
                            noOptionsText={"حدد المؤسسة"}
                            options={uniqueNomEtValues}
                            onChange={handleNomEtChange}
                            value={selectedNomEtValue}
                            defaultValue={uniqueNomEtValues[0]} // Set the defaultValue to the first option
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="المؤسسات"
                                fullWidth
                                InputLabelProps={{
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                              />
                            )}
                            renderOption={(props, option) => {
                              const { label, indexvalue } = option;
                              return (
                                <span
                                  {...props}
                                  style={{
                                    backgroundColor:
                                      indexvalue % 2 === 0
                                        ? "#add8e6"
                                        : "#FFFFFF",
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  }}
                                >
                                  {indexvalue === -1 ? label : label}
                                </span>
                              );
                            }}
                          />
                        </Grid>
                      ) : null}

                      {uniqueNomFormationValues.length > 0 ? (
                        <Grid item xs={2} sx={{ pr: 1 }}>
                          <Autocomplete
                            noOptionsText={"حدد النمط"}
                            options={uniqueNomFormationValues}
                            onChange={handleNomFormationChange}
                            value={selectedNomFormationValue}
                            defaultValue={uniqueNomFormationValues[0]} // Set the defaultValue to the first option
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="النمط"
                                fullWidth
                                InputLabelProps={{
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                              />
                            )}
                            renderOption={(props, option) => {
                              const { label, indexvalue } = option;
                              return (
                                <span
                                  {...props}
                                  style={{
                                    backgroundColor:
                                      indexvalue % 2 === 0
                                        ? "#add8e6"
                                        : "#FFFFFF",
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  }}
                                >
                                  {indexvalue === -1 ? label : label}
                                </span>
                              );
                            }}
                          />
                        </Grid>
                      ) : null}

                      {uniquespecialiteValues.length > 0 ? (
                        <Grid item xs={3} sx={{ pr: 1 }}>
                          <Autocomplete
                            noOptionsText={"حدد التخصص"}
                            options={uniquespecialiteValues}
                            onChange={handlespecialiteChange}
                            value={selectedspecialiteValue}
                            defaultValue={uniquespecialiteValues[0]} // Set the defaultValue to the first option
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="التخصص"
                                fullWidth
                                InputLabelProps={{
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                              />
                            )}
                            renderOption={(props, option) => {
                              const { label, indexvalue } = option;
                              return (
                                <span
                                  {...props}
                                  style={{
                                    backgroundColor:
                                      indexvalue % 2 === 0
                                        ? "#add8e6"
                                        : "#FFFFFF",
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  }}
                                >
                                  {indexvalue === -1 ? label : label}
                                </span>
                              );
                            }}
                          />
                        </Grid>
                      ) : null}

                      {uniquesemValues.length > 0 ? (
                        <Grid item xs={2} sx={{ pr: 1 }}>
                          <Autocomplete
                            noOptionsText={"حدد السداسي"}
                            options={uniquesemValues}
                            onChange={handlesemChange}
                            value={selectedsemValue}
                            defaultValue={uniquesemValues[0]} // Set the defaultValue to the first option
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="السداسي"
                                fullWidth
                                InputLabelProps={{
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                              />
                            )}
                            renderOption={(props, option) => {
                              const { label, indexvalue } = option;
                              return (
                                <span
                                  {...props}
                                  style={{
                                    backgroundColor:
                                      indexvalue % 2 === 0
                                        ? "#add8e6"
                                        : "#FFFFFF",
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  }}
                                >
                                  {indexvalue === -1 ? label : label}
                                </span>
                              );
                            }}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>

                  {loadingdata_mfep ? (
                    <>
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress color="success" />
                      </Box>
                    </>
                  ) : null}

                  <DataGrid
                    key={gridKey} // Use the key property to force remount
                    locale="ar-SD"
                    rows={filteredRows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    getRowId={(row) => row.id}
                    getRowClassName={(params) =>
                      parseInt(params.row.id_rows, 10) % 2 === 0
                        ? "even-row"
                        : "odd-row"
                    }
                    slots={{
                      toolbar: CustomToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        setRows,
                        setRowModesModel,
                        columns,
                        handleExportExcel,
                      },
                    }}
                    sx={{
                      boxShadow: 2,
                      border: 2,
                      borderColor: "primary.light",

                      "& .MuiDataGrid-cell": {
                        fontFamily: "Amiri",
                        fontSize: "20px",
                        textAlign: "center",
                        backgroundColor: "rgba(255,255,255,0.65)",
                        borderRight: "1px solid #ccc", // Add this line to apply right border to the cell
                      },

                      "& .MuiDataGrid-row:hover": {
                        backgroundColor: "#2b84dd", // Background color when row is hovered
                      },

                      "& .even-row": {
                        backgroundColor: "#2b84dd61", // Background color for even rows
                      },
                      "& .odd-row": {
                        backgroundColor: "#ffffff", // Background color for odd rows
                      },
                    }}
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 25, page: 0 },
                      },
                      columns: {
                        columnVisibilityModel: {
                          // Hide columns blog and tiktok, the other columns will remain visible
                          NoteMemoire: false,
                          NoteSoutenance: false,
                          NoteStage: false,
                        },
                      },
                    }}
                  />
                </Grid>
              </ThemeProvider>
            </CacheProvider>
          </Box>

          <Footer />
        </div>
        <SaveConfirmationDialog
          open={isSaveConfirmationOpen}
          onClose={handleSaveConfirmationResponse}
        />
      </Box>
    </>
  );
}

export default ShowAllAprsDfep;
